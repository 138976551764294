import { Box, Toolbar, Typography } from "@mui/material";
import {
  DataLabActions,
  DataLabStatusLabel,
  useDataLabContext,
} from "features/dataLabs";

interface DataLabToolbarProps {
  id: string;
}

const DataLabToolbar: React.FC<DataLabToolbarProps> = ({ id }) => {
  const {
    dataLab: { data: dataLab, loading },
  } = useDataLabContext();
  if (loading || !dataLab) return <></>;
  return (
    <Toolbar
      disableGutters={true}
      sx={({ spacing }) => ({ padding: spacing(0, 2) })}
    >
      <Typography
        fontWeight="fontWeightSemiBold"
        mr={1}
        noWrap={true}
        variant="h6"
      >
        {!dataLab?.name ? "Loading datalab name..." : dataLab.name}
      </Typography>
      <Box display="flex" flex={1}>
        <DataLabStatusLabel isValidated={dataLab.isValidated} />
        <Box ml="auto">
          <DataLabActions id={id} />
        </Box>
      </Box>
    </Toolbar>
  );
};

DataLabToolbar.displayName = "DataLabToolbar";

export default DataLabToolbar;
