import { useAuth0 } from "@auth0/auth0-react";
import { InfoTooltip } from "@decentriq/components";
import { useDataRoomRequestTempApproversQuery } from "@decentriq/graphql/dist/hooks";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  type Theme,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import DataRoomTableParticipantAvatar from "components/entities/dataRoom/DataRoomParticipantAvatar/DataRoomParticipantAvatar";

interface RequestApproversProps {
  requestId: string;
}

const useTableStyles = makeStyles()((theme: Theme) => ({
  cell: {
    padding: "8px !important",
  },
}));

const RequestApprovers: React.FC<RequestApproversProps> = ({ requestId }) => {
  const { user } = useAuth0();
  const currentUserEmail = user?.email;
  const { classes: tableClasses } = useTableStyles();
  const { data } = useDataRoomRequestTempApproversQuery({
    variables: {
      id: requestId,
    },
  });
  const approvers = data?.dataRoomRequest?.tempApprovers;
  return (
    <>
      <Typography variant="body2">
        <strong>Affected data owners:</strong>
        <InfoTooltip tooltip="All participants with permissions to provision datasets to dependencies of this computation are required to approve the request." />
      </Typography>
      {approvers?.length ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table>
            <TableBody>
              {approvers.map((a) => (
                <TableRow key={a}>
                  <TableCell className={tableClasses.cell}>
                    <Box alignItems="center" display="flex">
                      <DataRoomTableParticipantAvatar name={a} />
                      <Typography
                        style={{
                          marginLeft: "0.25rem",
                        }}
                      >
                        {a}
                        {a === currentUserEmail && (
                          <strong style={{ marginLeft: ".5rem" }}>(You)</strong>
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ marginTop: "6px" }} variant="body2">
          {approvers
            ? "no dependencies yet"
            : "Please validate the computation to determine the affected data owners."}
        </Typography>
      )}
    </>
  );
};

RequestApprovers.displayName = "RequestApprovers";

export default memo(RequestApprovers);
