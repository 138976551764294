import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
} from "features/datasets/components/ExternalConnections";
import {
  S3BucketFormConfigurationFields,
  S3BucketFormCredentials,
} from "features/datasets/components/ImportData/ImportExternalDataDialog/components";
import { SegmentOwnersField } from "./components";

type AdFormProps = ExportDatasetFormProps;

const AdFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    filePath: yup.string().trim().required("File path is required"),
    region: yup.string().trim().required("Region is required"),
    url: yup.string().trim().required("S3 Bucket URL is required"),
  }),
  credentials: yup.object({
    accessKey: yup.string().trim().required("Access Key is required"),
    secretKey: yup.string().trim().required("Secret Key is required"),
  }),
  segmentOwners: yup
    .array()
    .of(yup.object({ id: yup.string().required("Segment Owner is required") }))
    .required()
    .min(1, "At least one segment owner is required"),
});

type AdFormValues = yup.Asserts<typeof AdFormValidationSchema>;

const AdForm: React.FC<AdFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
}) => {
  const adFormHookForm = useForm({
    defaultValues: {
      configuration: {
        filePath: defaultValues?.datasetName || "",
        region: "",
        url: "",
      },
      credentials: {
        accessKey: "",
        secretKey: "",
      },
      segmentOwners: [{ id: "" }],
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(AdFormValidationSchema),
  });
  const { handleSubmit, reset, control } = adFormHookForm;

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: AdFormValues) => {
      const {
        configuration: { filePath, url, region },
        credentials: { accessKey, secretKey },
        segmentOwners,
      } = formValues;
      const segmentOwnersIds = segmentOwners.map(({ id }) => id);

      onSubmit({
        input: {
          adformDsp: {
            aws: {
              credentials: {
                accessKey: accessKey.trim(),
                secretKey: secretKey.trim(),
              },
              targetConfig: {
                bucket: url.trim(),
                objectKey: filePath.trim(),
                region: region.trim(),
              },
            },
            segmentOwners: segmentOwnersIds,
          },
        },
      });
      reset();
    },
    [reset, onSubmit]
  );

  return (
    <Box>
      <FormProvider {...adFormHookForm}>
        <Stack spacing={1}>
          <ExternalConnectionConfigurationLabel />
          <S3BucketFormConfigurationFields />
          <Controller
            control={control}
            name="configuration.filePath"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.filePath;
              return (
                <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                  <InputLabel>Object name (Key)</InputLabel>
                  <OutlinedInput
                    label="Object name (Key)"
                    placeholder="Example: my-file.csv"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <SegmentOwnersField />
          <S3BucketFormCredentials />
        </Stack>
      </FormProvider>
      <ExternalConnectionActionsWrapper>
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </Box>
  );
};

AdForm.displayName = "AdForm";

export default memo(AdForm);
