import {
  type DraftParticipant,
  type PublishedParticipant,
} from "@decentriq/graphql/dist/types";
import { Box, FormControl, Grid } from "@mui/material";
import { memo } from "react";
import {
  ComputeNodesPermissionsSelect,
  DataNodesPermissionsSelect,
  DeleteParticipantButton,
} from "components";
import { usePermissionsVars } from "contexts";
import ParticipantEmailField from "../ParticipantEmailField/ParticipantEmailField";

interface ParticipantFormProps {
  participant: DraftParticipant | PublishedParticipant;
}

const ParticipantForm: React.FC<ParticipantFormProps> = memo(
  ({ participant }) => {
    const { canEditPermissions } = usePermissionsVars();
    return (
      <Grid
        alignItems="flex-end"
        container={true}
        spacing={0}
        style={{ alignItems: "center", flexWrap: "nowrap", width: "100%" }}
      >
        <Grid alignItems="center" item={true} xs={5}>
          <Box sx={{ p: 0.5 }}>
            <FormControl fullWidth={true}>
              <ParticipantEmailField participant={participant} />
            </FormControl>
          </Box>
        </Grid>
        <Grid item={true} xs={3}>
          <FormControl fullWidth={true}>
            <DataNodesPermissionsSelect participant={participant} />
          </FormControl>
        </Grid>
        <Grid item={true} xs={3}>
          <FormControl fullWidth={true}>
            <ComputeNodesPermissionsSelect participant={participant} />
          </FormControl>
        </Grid>
        {canEditPermissions ? (
          <Grid alignItems="center" item={true} xs="auto">
            <DeleteParticipantButton idForParticipant={participant?.id} />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);

ParticipantForm.displayName = "ParticipantForm";

export default ParticipantForm;
