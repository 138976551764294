import { DOMAIN_REGEXP } from "constants/index";
import {
  useOrganizationDomainsQuery,
  useUpdateOrganizationDomainsMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  Box,
  Chip as MuiChip,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { type RefObject, useCallback, useMemo, useRef, useState } from "react";
import { Chip } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";

interface OrganizationDomainEditorProps {
  organizationId: string;
}

const OrganizationDomainEditor: React.FC<OrganizationDomainEditorProps> = ({
  organizationId,
}) => {
  const [domain, setDomain] = useState<string>("");
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const { data: organizationDomainsData } = useOrganizationDomainsQuery({
    variables: { organizationId },
  });
  const organizationDomains = useMemo(
    () => organizationDomainsData?.organization?.domains || [],
    [organizationDomainsData]
  );

  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  const domainEditorRef = useRef<RefObject<HTMLInputElement>>(null);

  const withDomainValidationError = domain && !domain.match(DOMAIN_REGEXP);
  const withDomainUniquenessError = organizationDomains.includes(domain);
  const withError = withDomainValidationError || withDomainUniquenessError;

  const [updateOrganizationDomainsMutation, { loading }] =
    useUpdateOrganizationDomainsMutation({
      onCompleted: () => {
        enqueueSnackbar(`Organization domains have been successfully updated.`);
        setIsTouched(false);
        setDomain("");
        if (domainEditorRef?.current) {
          domainEditorRef.current.value = "";
        }
      },
      onError: (error) => {
        enqueueSnackbar("Organization domains could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
    });

  const addOrganizationDomain = useCallback(
    (event: any) => {
      const { target } = event || {};
      if (!target.value || withError) return;
      const allowedDomains = [...organizationDomains, target.value.trim()];
      return updateOrganizationDomainsMutation({
        update: (cache) => {
          cache.modify({
            fields: {
              users: (existing = {}) => {
                return {
                  ...existing,
                  domains: allowedDomains,
                };
              },
            },
            id: cache.identify({
              __typename: "Organization",
              id: organizationId,
            }),
          });
        },
        variables: {
          input: {
            allowedDomains,
            id: organizationId,
          },
        },
      });
    },
    [
      organizationDomains,
      organizationId,
      updateOrganizationDomainsMutation,
      withError,
    ]
  );

  const addOrganizationDomainOnEnter = useCallback(
    (event: any) => {
      const { target, keyCode } = event || {};
      if (keyCode !== 13 || !target.value) return;
      addOrganizationDomain(event);
    },
    [addOrganizationDomain]
  );

  const removeOrganizationDomain = useCallback(
    (domain: string) => {
      const allowedDomains = organizationDomains.filter(
        (existingDomain) => domain !== existingDomain
      );
      return updateOrganizationDomainsMutation({
        update: (cache) => {
          cache.modify({
            fields: {
              users: (existing = {}) => {
                return {
                  ...existing,
                  domains: allowedDomains,
                };
              },
            },
            id: cache.identify({
              __typename: "Organization",
              id: organizationId,
            }),
          });
        },
        variables: {
          input: {
            allowedDomains,
            id: organizationId,
          },
        },
      });
    },
    [organizationDomains, organizationId, updateOrganizationDomainsMutation]
  );

  const handleDomainChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setDomain(target.value);
      if (!isTouched) setIsTouched(true);
    },
    [isTouched]
  );

  return (
    <FormControl fullWidth={true}>
      <FormLabel>Allowed email domains</FormLabel>
      {isSuperAdmin ? (
        <FormControl error={isTouched && withError} fullWidth={true}>
          <OutlinedInput
            disabled={loading}
            inputRef={domainEditorRef}
            name="domain"
            onBlur={addOrganizationDomain}
            onChange={handleDomainChange}
            onKeyDown={addOrganizationDomainOnEnter}
            placeholder="+ Add a new domain"
            value={domain}
          />
          <FormHelperText>
            {isTouched
              ? withDomainValidationError
                ? "Domain is invalid"
                : withDomainUniquenessError
                  ? "This domain is already in use"
                  : ""
              : ""}
          </FormHelperText>
          <Stack direction="row" mt={1}>
            {organizationDomains.map((domain) => (
              <MuiChip
                key={domain}
                label={domain?.toUpperCase()}
                onDelete={() => removeOrganizationDomain(domain)}
                sx={{
                  borderRadius: "2px",
                  marginRight: 1,
                }}
              />
            ))}
          </Stack>
        </FormControl>
      ) : organizationDomains.length ? (
        <Box sx={{ mt: 0.5 }}>
          {organizationDomains.map((domain) => (
            <Chip key={domain} label={domain?.toUpperCase()} sx={{ mr: 1 }} />
          ))}
        </Box>
      ) : (
        "—"
      )}
    </FormControl>
  );
};

export default OrganizationDomainEditor;
