import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
} from "features/datasets/components/ExternalConnections";

type MetaFormProps = ExportDatasetFormProps;

const metaFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    audienceName: yup.string().trim().required("Audience name is required"),
  }),
  credentials: yup.object({
    accessToken: yup.string().trim().required("Access token is required"),
    accountId: yup.string().trim().required("Ad Account ID is required"),
  }),
});

type MetaFormValues = yup.InferType<typeof metaFormValidationSchema>;

const MetaForm: React.FC<MetaFormProps> = ({
  onCancel: cancelFormHandler,
  onSubmit: submitFormHandler,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        audienceName: "",
      },
      credentials: {
        accessToken: "",
        accountId: "",
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(metaFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: MetaFormValues) => {
      const {
        configuration: { audienceName = "" } = {},
        credentials: { accessToken = "", accountId = "" } = {},
      } = formValues;

      submitFormHandler({
        input: {
          meta: {
            accessToken: accessToken.trim(),
            adAccountId: accountId.trim(),
            audienceName: audienceName.trim(),
          },
        },
      });
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <form>
        <Stack spacing={1}>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.audienceName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.audienceName;
              return (
                <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                  <InputLabel>Audience name</InputLabel>
                  <OutlinedInput
                    label="Audience name"
                    placeholder="Example: DCR Audience"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <ExternalConnectionCredentialsLabel />
          <Controller
            control={control}
            name="credentials.accountId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.accountId;
              return (
                <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                  <InputLabel>Account ID</InputLabel>
                  <OutlinedInput
                    label="Account ID"
                    placeholder="Example: 397218503624195"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="credentials.accessToken"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.accessToken;
              return (
                <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                  <InputLabel>Access Token</InputLabel>
                  <OutlinedInput
                    autoComplete="off"
                    label="Access Token"
                    placeholder="Example: F57yT3QaX2nD8jHr4KlP9cVs1W6zU0E2sY8hN4bM..."
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Stack>
      </form>
      <ExternalConnectionActionsWrapper>
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </Box>
  );
};

MetaForm.displayName = "MetaForm";

export default memo(MetaForm);
