import {
  faArrowLeft,
  faCircleExclamation,
  faSparkles,
} from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAudiencePresentation } from "features/mediaDataRoom/types";
import {
  EmptyStateContainer,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import {
  AudienceGeneratorReachSlider,
  AudienceQualityStatistics,
  AudienceQualityStatisticsItemBox,
  ExcludeSeedAudienceCheckbox,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";
import { useOrganizationPreferences } from "hooks";

const AdvertiserAudienceGenerator: React.FC = () => {
  const {
    selectedAudienceType,
    setSelectedAudienceType,
    generateLookalikeAudience,
    generateLookalikeAudienceLoading,
    openExcludeSeedAudienceInfoDialog,
    selectedLookalikeAudience,
    activatedLookalikeAudiences = [],
    availableAudiences: {
      computeResults: { audienceTypes = [] } = {},
      status: availableAudiencesStatus,
      error: availableAudiencesError,
      retry: retryAvailableAudiences,
    },
  } = useAdvertiserAudienceGenerator();

  const [retryInProgress, setRetryInProgress] = useState(false);

  const { dataRoomId } = usePublishedMediaInsightsDcr();
  const navigate = useNavigate();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const {
    isDeactivated,
    supportedFeatures: { enableExtendedLalQualityStats = false },
  } = usePublishedMediaInsightsDcr();

  const { allowExcludingSeedAudience } = useOrganizationPreferences();

  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    try {
      await retryAvailableAudiences();
    } finally {
      setRetryInProgress(false);
    }
  }, [retryAvailableAudiences, isDeactivated]);

  if (availableAudiencesStatus !== "COMPLETED") {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress color="inherit" size="1.5rem" thickness={1} />
      </Box>
    );
  }

  if (isDeactivated || availableAudiencesError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography mt={1} variant="subtitle2">
          Audience data could not be retrieved
        </Typography>
        {!isDeactivated && (
          <LoadingButton
            color="inherit"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
            variant="outlined"
          >
            Retry
          </LoadingButton>
        )}
      </EmptyStateContainer>
    );
  }

  if (audienceTypes.length === 0) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          There are not enough individuals in the overlap.
        </Typography>
        <Typography variant="subtitle2">
          Please check if the Matching ID is compatible.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <Grid container={true}>
      <Grid container={true} mb={2}>
        <Button
          color="secondary"
          onClick={() => navigate(`/datarooms/mi/${dataRoomId}/activation`)}
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          variant="text"
        >
          Back
        </Button>
      </Grid>
      <Grid container={true} xs={12}>
        <Grid item={true} xs={7}>
          <Typography variant="h6">
            1. Select Your Advertiser Audience
          </Typography>
          <Typography variant="subtitle2">
            Choose the audience that will be used to seed your lookalike model.
          </Typography>
        </Grid>
        <Grid item={true} xs={5}>
          <FormControl fullWidth={true}>
            <FormLabel sx={{ fontSize: "12px" }}>Advertiser Audience</FormLabel>
            <Select
              displayEmpty={true}
              onChange={(event: SelectChangeEvent<string>) =>
                setSelectedAudienceType(event?.target.value)
              }
              renderValue={getAudiencePresentation}
              value={selectedAudienceType || ""}
            >
              {audienceTypes.map((audienceType) => {
                return (
                  <MenuItem key={audienceType} value={audienceType}>
                    <ListItemText
                      primary={getAudiencePresentation(audienceType)}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {enableExtendedLalQualityStats && (
            <Box display="flex" gap={2} mt={2}>
              <AudienceQualityStatisticsItemBox flex={1}>
                <Typography variant="subtitle2">
                  Addressable audience
                </Typography>
                <Typography fontWeight={800}>
                  {formatter.format(
                    selectedLookalikeAudience?.addressable_audience_size || 0
                  )}
                </Typography>
              </AudienceQualityStatisticsItemBox>
              <AudienceQualityStatisticsItemBox flex={1}>
                <Typography variant="subtitle2">Seed audience</Typography>
                <Typography fontWeight={800}>
                  {formatter.format(
                    selectedLookalikeAudience?.seed_audience_size || 0
                  )}
                </Typography>
              </AudienceQualityStatisticsItemBox>
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider
        sx={({ spacing }) => ({ margin: spacing(4, 0), width: "100%" })}
      />
      <Grid container={true} xs={12}>
        <Grid item={true} xs={7}>
          <Typography variant="h6">2. Define the Reach</Typography>
          <Typography variant="subtitle2">
            Set the precision and reach for your lookalike model
          </Typography>
        </Grid>
        <Grid item={true} xs={5}>
          <AudienceGeneratorReachSlider />
          {allowExcludingSeedAudience && <ExcludeSeedAudienceCheckbox />}
        </Grid>
        <Grid container={true} item={true} xs={12}>
          <AudienceQualityStatistics />
        </Grid>
      </Grid>
      <Divider
        sx={({ spacing }) => ({ margin: spacing(4, 0), width: "100%" })}
      />
      <Grid container={true}>
        <Grid item={true} xs={7}>
          <Typography variant="h6">
            3. Generate the Lookalike Audience
          </Typography>
          <Typography variant="subtitle2">
            Click the button to create your targeted audience
          </Typography>
        </Grid>
        <Grid container={true} item={true} justifyContent="flex-end" xs={5}>
          <LoadingButton
            color="primary"
            disabled={!selectedAudienceType}
            loading={generateLookalikeAudienceLoading}
            loadingPosition="start"
            onClick={
              !allowExcludingSeedAudience ||
              activatedLookalikeAudiences.length > 0
                ? generateLookalikeAudience
                : openExcludeSeedAudienceInfoDialog
            }
            startIcon={<FontAwesomeIcon icon={faSparkles} />}
            sx={{ height: "fit-content" }}
            variant="contained"
          >
            Generate results
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

AdvertiserAudienceGenerator.displayName = "AdvertiserAudienceGenerator";

export default AdvertiserAudienceGenerator;
