import { InfoTooltip } from "@decentriq/components";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
} from "features/datasets/components/ExternalConnections";

type GoogleAdManagerFormProps = ExportDatasetFormProps;

enum GoogleAdIdentifierKind {
  cookie_encrypted = "cookie_encrypted",
  cookie_idfa = "cookie_idfa",
  ppid = "ppid",
  cookie_rida = "cookie_rida",
  cookie_tvos = "cookie_tvos",
}

const GoogleAdManagerFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    bucket: yup
      .string()
      .trim()
      .required("Ad Manager storage bucket is required"),
    identifierKind: yup
      .mixed()
      .oneOf(Object.values(GoogleAdIdentifierKind))
      .required("Identifier is required"),
    object: yup.string().trim().required("Object name is required"),
    segment: yup.string().trim().required("Audience segment ID is required"),
  }),
  credentialsFile: yup.mixed().required("Credentials file is required"),
});

type GoogleAdManagerFormValues = yup.InferType<
  typeof GoogleAdManagerFormValidationSchema
>;

const GoogleAdManagerForm: React.FC<GoogleAdManagerFormProps> = ({
  onCancel: cancelFormHandler,
  onSubmit: submitFormHandler,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        bucket: "gdfp_cookieupload_[Ad Manager network code]",
        identifierKind: GoogleAdIdentifierKind.cookie_encrypted,
        object: "",
        segment: "",
      },
      credentialsFile: undefined,
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(GoogleAdManagerFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: GoogleAdManagerFormValues) => {
      const {
        configuration: {
          segment = "",
          bucket = "",
          object = "",
          identifierKind = "",
        } = {},
        credentialsFile,
      } = formValues;

      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const credentialsJSON = JSON.parse(reader?.result as string);
          const credentials = JSON.stringify(credentialsJSON, null, 4);
          if (!credentials) {
            throw new Error("Credentials file is not valid");
          }
          submitFormHandler({
            input: {
              googleAdManager: {
                bucket: bucket.trim(),
                credentials,
                identifierKind,
                inputHasHeader: false,
                listId: segment.trim(),
                objectName: object.trim(),
              },
            },
          });
        };
        reader.readAsText(credentialsFile);
      } catch (error) {
        throw new Error(error as string);
      }
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <form>
        <Stack spacing={1}>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.identifierKind"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.identifierKind;
              const withError = !isEmpty(fieldError);
              return (
                <FormControl
                  error={withError}
                  sx={{ mb: 0.5, mt: 1, width: "260px" }}
                >
                  <InputLabel>Identifier</InputLabel>
                  <Select displayEmpty={true} label="Identifier" {...field}>
                    {Object.keys(GoogleAdIdentifierKind).map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  {withError && (
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.segment"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.segment;
              return (
                <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                  <InputLabel>Audience segment ID</InputLabel>
                  <OutlinedInput
                    label="Audience segment ID"
                    placeholder="Example: 3153490"
                    sx={{ mb: 1, width: "260px" }}
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Box>
            <Grid columnSpacing={1} container={true}>
              <Grid xs={8}>
                <Controller
                  control={control}
                  name="configuration.bucket"
                  render={({ field, formState }) => {
                    const { errors } = formState;
                    const fieldError = errors?.configuration?.bucket;
                    return (
                      <FormControl
                        error={!isEmpty(fieldError)}
                        fullWidth={true}
                      >
                        <InputLabel>Ad Manager storage bucket</InputLabel>
                        <OutlinedInput
                          label="Ad Manager storage bucket"
                          placeholder="Example: 1234512345"
                          {...field}
                        />
                        <FormHelperText>{fieldError?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid xs={4}>
                <Controller
                  control={control}
                  name="configuration.object"
                  render={({ field, formState }) => {
                    const { errors } = formState;
                    const fieldError = errors?.configuration?.object;
                    return (
                      <FormControl
                        error={!isEmpty(fieldError)}
                        fullWidth={true}
                      >
                        <InputLabel>Object name</InputLabel>
                        <OutlinedInput
                          label="Object name"
                          placeholder="Example: audience_export_decentriq.csv"
                          {...field}
                        />
                        <FormHelperText>{fieldError?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <ExternalConnectionCredentialsLabel />
          <Controller
            control={control}
            name="credentialsFile"
            render={({
              field: { value, onChange, ...restField },
              formState,
            }) => {
              const { errors } = formState;
              const fieldError = errors?.credentialsFile;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <InputLabel shrink={true}>
                    Service account key in JSON format
                    <InfoTooltip
                      tooltip={
                        <>
                          Please{" "}
                          <a
                            href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                            rel="noreferrer"
                            style={{ color: "inherit" }}
                            target="_blank"
                          >
                            follow the instructions
                          </a>{" "}
                          to obtain a service account key
                        </>
                      }
                    />
                  </InputLabel>
                  <OutlinedInput
                    label={
                      <>
                        Service account key in JSON format
                        <InfoTooltip
                          tooltip={
                            <>
                              Please{" "}
                              <a
                                href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                                rel="noreferrer"
                                style={{ color: "inherit" }}
                                target="_blank"
                              >
                                follow the instructions
                              </a>{" "}
                              to obtain a service account key
                            </>
                          }
                        />
                      </>
                    }
                    notched={true}
                    {...restField}
                    inputProps={{
                      accept: "application/JSON",
                      multiple: false,
                      onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange((event?.target?.files || [])[0]),
                      type: "file",
                    }}
                    value={value?.fileName}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Stack>
      </form>
      <ExternalConnectionActionsWrapper>
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </Box>
  );
};

GoogleAdManagerForm.displayName = "GoogleAdManagerForm";

export default memo(GoogleAdManagerForm);
