import { InfoTooltip } from "@decentriq/components";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  useFormContext,
} from "react-hook-form";
import { type CollaborationTypes } from "models";

interface ConfigurationFormFeaturesFieldProps<F extends FieldValues> {
  featuresList: CollaborationTypes[];
  fieldName: Path<F>;
  tooltip?: string;
  testId?: string;
  optionTestId?: string;
}

type ConfigurationFormFeaturesFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormFeaturesFieldProps<F>
) => React.ReactNode;

const ConfigurationFormFeaturesField = memo(
  <F extends FieldValues>({
    featuresList,
    fieldName,
    tooltip,
    testId,
    optionTestId,
  }: ConfigurationFormFeaturesFieldProps<F>) => {
    const { control: formControl, watch } = useFormContext<F>();
    const selectedFeatures = watch(fieldName);
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[fieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          const withError = !isEmpty(fieldError);
          return (
            <FormControl error={withError} fullWidth={true}>
              <FormLabel>
                Collaboration types
                {tooltip && <InfoTooltip tooltip={tooltip} />}
              </FormLabel>
              <Select
                MenuProps={{ sx: { maxHeight: "370px" } }}
                displayEmpty={true}
                multiple={true}
                renderValue={(selected) => {
                  return selected.length === 0
                    ? "0 types"
                    : selected.join(", ");
                }}
                {...field}
                {...(testId ? { "data-testid": testId } : {})}
              >
                {featuresList.map((dataRoomFeature) => (
                  <MenuItem
                    key={dataRoomFeature}
                    sx={({ spacing }) => ({ padding: spacing(1, 2) })}
                    value={dataRoomFeature}
                    {...(optionTestId
                      ? {
                          "data-testid": `${optionTestId}${dataRoomFeature.toLowerCase()}`,
                        }
                      : {})}
                  >
                    <Checkbox
                      checked={selectedFeatures.includes(
                        dataRoomFeature as CollaborationTypes
                      )}
                    />
                    <ListItemText primary={dataRoomFeature} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);

ConfigurationFormFeaturesField.displayName = "ConfigurationFormFeaturesField";

export default ConfigurationFormFeaturesField as ConfigurationFormFeaturesFieldComponent;
