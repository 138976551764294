import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/material";
import { memo } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";
import { ImageUploadButton } from "components";

interface DisplayConfigurationLogoFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  testId?: string;
}

type DisplayConfigurationLogoFieldComponent = <F extends FieldValues>(
  props: DisplayConfigurationLogoFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationLogoField = memo(
  <F extends FieldValues>({
    fieldName,
    testId,
  }: DisplayConfigurationLogoFieldProps<F>) => {
    const { control: formControl } = useFormContext<F>();
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field }) => (
          <Box
            sx={(theme) => ({
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              mb: theme.spacing(2),
            })}
          >
            {field.value && (
              <img
                alt={"display configuration logo"}
                loading="lazy"
                src={`data:image;base64,${field.value}`}
                style={{ height: 125 }}
              />
            )}
            <Box sx={(theme) => ({ mt: theme.spacing(1) })}>
              <ImageUploadButton
                imageCropperHookOptions={{
                  aspectRatio: 4 / 1,
                  canvasSize: { height: 150, width: 600 },
                  fillMode: "transparent",
                  minImageDimensions: { height: 50, width: 200 },
                }}
                onImageSelected={field.onChange}
                testIdPrefix={testId}
              />
              {field.value && (
                <Button
                  onClick={(event) => field.onChange(event, undefined)}
                  startIcon={<FontAwesomeIcon icon={faXmark} />}
                  sx={{ height: "fit-content", marginLeft: 2 }}
                  variant="outlined"
                >
                  Remove logo
                </Button>
              )}
            </Box>
          </Box>
        )}
      />
    );
  }
);

DisplayConfigurationLogoField.displayName = "DisplayConfigurationLogoField";

export default DisplayConfigurationLogoField as DisplayConfigurationLogoFieldComponent;
