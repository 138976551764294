import { faCheck, faClock, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useMemo, useState } from "react";
import DataNodeDataButton from "features/dataNodes/components/DataNodeConstructor/DataNodeDataButton";
import {
  type DatasetDetailsConfigContextValues,
  DatasetDetailsConfigProvider,
  type DatasetPopupConfigContextValues,
  DatasetPopupConfigProvider,
} from "features/datasets";
import { usePublishedMediaInsightsDcr } from "../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import type { PublisherDatasetsHashes } from "../../models";
import DataNodeItem from "../DataNodeItem";
import DataLabConnectDialog from "./components/DataLabConnectDialog";
import { PublisherDataNodeDeprovisionButton } from "./components";

interface PublisherDataNodeActionsProps {
  isProvisioned: boolean;
  retrieveDatasets: () => Promise<void>;
}

const PublisherDataNodeActions: React.FC<PublisherDataNodeActionsProps> = ({
  retrieveDatasets,
  isProvisioned,
}) => {
  const { isPublisher, dataRoomId, isDeactivated } =
    usePublishedMediaInsightsDcr();
  const [openConnectDialog, setOpenConnectDialog] = useState(false);
  const toggleOpenConnectDialog = useCallback(
    () => setOpenConnectDialog((state) => !state),
    [setOpenConnectDialog]
  );
  const canProvision = isPublisher && !isProvisioned && !isDeactivated;
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      sx={{ marginRight: "8px" }}
    >
      {canProvision ? (
        <Button
          color="primary"
          onClick={toggleOpenConnectDialog}
          startIcon={<FontAwesomeIcon fixedWidth={true} icon={faUpload} />}
          style={{ marginLeft: "16px", maxWidth: "260px" }}
          variant="contained"
        >
          Provision datalab
        </Button>
      ) : (
        <Typography
          color={grey[400]}
          component="div"
          fontWeight="bold"
          variant="body2"
        >
          {isProvisioned ? (
            isPublisher ? (
              <PublisherDataNodeDeprovisionButton
                retrieveDatasets={retrieveDatasets}
              />
            ) : (
              <>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faCheck}
                  style={{ marginRight: "4px" }}
                />
                Data provisioned
              </>
            )
          ) : (
            <>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faClock}
                style={{ marginRight: "4px" }}
              />
              Waiting for data
            </>
          )}
        </Typography>
      )}
      <DataLabConnectDialog
        dataRoomId={dataRoomId}
        onCancel={toggleOpenConnectDialog}
        open={openConnectDialog}
        retrieveDatasets={retrieveDatasets}
      />
    </Box>
  );
};

const PublishedDataNodeContent: React.FC<PublisherDatasetsHashes> = ({
  usersDatasetHash,
  segmentsDatasetHash,
  demographicsDatasetHash,
  embeddingsDatasetHash,
}) => {
  const datasetsWithLabels: { label: string; hash: string | undefined }[] = [
    { hash: usersDatasetHash || undefined, label: "Users" },
    { hash: segmentsDatasetHash || undefined, label: "Segments" },
    { hash: demographicsDatasetHash || undefined, label: "Demographics" },
    { hash: embeddingsDatasetHash || undefined, label: "Embeddings" },
  ].filter(({ hash }) => Boolean(hash));
  const datasetPopupConfig = useMemo<DatasetPopupConfigContextValues>(
    () => ({ disableDelete: true, disableExport: true }),
    []
  );
  const datasetDetailsConfig = useMemo<DatasetDetailsConfigContextValues>(
    () => ({
      disableDeprovision: true,
      disableProvisionedDataRoomNavigation: true,
    }),
    []
  );
  return (
    <DatasetDetailsConfigProvider value={datasetDetailsConfig}>
      <DatasetPopupConfigProvider value={datasetPopupConfig}>
        <Box sx={{ padding: (theme) => theme.spacing(1, 0) }}>
          <Grid container={true} spacing={2}>
            {datasetsWithLabels.map(({ label, hash }, index) => (
              <Grid item={true} key={index} xs={6}>
                <Typography fontWeight={500} variant="body2">
                  {label}
                </Typography>
                <DataNodeDataButton
                  dataType="table"
                  datasetHash={hash}
                  id={`${label.toLocaleLowerCase()}_leaf`}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DatasetPopupConfigProvider>
    </DatasetDetailsConfigProvider>
  );
};

interface PublisherDataNodeProps extends PublisherDatasetsHashes {
  retrieveDatasets: () => Promise<void>;
}

const PublisherDataNode: React.FC<PublisherDataNodeProps> = ({
  retrieveDatasets,
  ...datasetsHashes
}) => {
  const { usersDatasetHash } = datasetsHashes;
  const { isPublisher } = usePublishedMediaInsightsDcr();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = useCallback(
    () => setIsCollapsed((state) => !state),
    [setIsCollapsed]
  );
  const isProvisioned = Boolean(usersDatasetHash);
  const hasUploadingDescription = isProvisioned && isPublisher;
  return (
    <DataNodeItem
      actions={
        <PublisherDataNodeActions
          isProvisioned={isProvisioned}
          retrieveDatasets={retrieveDatasets}
        />
      }
      content={
        hasUploadingDescription ? (
          <PublishedDataNodeContent {...datasetsHashes} />
        ) : null
      }
      isCollapsed={isCollapsed}
      title="Publisher data"
      toggleCollapse={hasUploadingDescription ? toggleCollapse : undefined}
    />
  );
};

PublisherDataNode.displayName = "PublisherDataNode";

export default PublisherDataNode;
