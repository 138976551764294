import {
  faEye,
  faEyeSlash,
  faKeySkeletonLeftRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { generate as generatePassword } from "generate-password-browser";
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useCopyToClipboard } from "hooks";
import { passwordRegExp } from "utils/validation";
import { useKeychain } from "wrappers";

const validationSchema = yup.object({
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Please repeat your password")
    .defined(),
  oldPassword: yup
    .string()
    .trim()
    .required("Old password is required")
    .defined(),
  password: yup
    .string()
    .trim()
    .min(
      10,
      "Your password must contain at least 10 characters, lower case and upper case letters, and numbers."
    )
    .matches(
      passwordRegExp,
      "Your password must contain at least 10 characters, lower case and upper case letters, and numbers."
    )
    .notOneOf(
      [yup.ref("oldPassword")],
      "New password should differ from old one"
    )
    .required()
    .defined(),
});

const passwordSuggestion = () =>
  generatePassword({
    length: 14,
    lowercase: true,
    numbers: true,
    strict: true,
    uppercase: true,
  });

type ChangeKeychainPasswordDialogFormValues = yup.InferType<
  typeof validationSchema
>;

interface ChangeKeychainPasswordDialogProps {
  open: boolean;
  onCancel: () => void;
}

const ChangeKeychainPasswordDialog: React.FC<
  ChangeKeychainPasswordDialogProps
> = ({ open, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  const { changeKeychainPassword } = useKeychain();
  const [changing, setChanging] = useState(false);
  const [suggestedPassword, setSuggestedPassword] = useState<
    string | undefined
  >();
  const [oldPassword, setOldPassword] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  const validate = useCallback(
    async (
      values: ChangeKeychainPasswordDialogFormValues
    ): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  useEffect(() => {
    if (password === undefined) {
      return;
    }
    validate({
      confirmPassword: confirmPassword || "",
      oldPassword: oldPassword || "",
      password,
    });
  }, [password, confirmPassword, oldPassword, validate]);
  const handleSubmit = useCallback(
    async (
      oldPassword: string | undefined,
      password: string | undefined,
      confirmPassword: string | undefined
    ) => {
      setChanging(true);
      const isValid = await validate({
        confirmPassword: confirmPassword || "",
        oldPassword: oldPassword || "",
        password: password || "",
      });
      if (isValid) {
        const changed = await changeKeychainPassword(
          oldPassword!.trim(),
          password!.trim()
        );
        if (changed) {
          onCancel();
        }
      }
      setChanging(false);
    },
    [setChanging, changeKeychainPassword, validate, onCancel]
  );
  const handlePasswordFocus = useCallback(
    (password: string | undefined) => {
      if (suggestedPassword) {
        return;
      }
      if (!password || !password.trim().length) {
        const newPassword = passwordSuggestion();
        setSuggestedPassword(newPassword);
      }
    },
    [setSuggestedPassword, suggestedPassword]
  );
  const handlePasswordSuggestionUse = useCallback(
    (suggestedPassword: string) => {
      copyToClipboard(suggestedPassword);
      enqueueSnackbar("Password copied to clipboard", {
        autoHideDuration: 1500,
      });
      setPassword(suggestedPassword);
      setConfirmPassword(suggestedPassword);
      setSuggestedPassword(undefined);
    },
    [copyToClipboard, enqueueSnackbar, setPassword, setConfirmPassword]
  );
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={changing ? undefined : onCancel}
      open={open}
    >
      <DialogTitle>
        <FontAwesomeIcon fixedWidth={true} icon={faKeySkeletonLeftRight} />{" "}
        Change Keychain password
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(oldPassword, password, confirmPassword);
          }}
        >
          <FormControl fullWidth={true}>
            <InputLabel>Current Keychain password</InputLabel>
            <OutlinedInput
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowOldPassword} tabIndex={-1}>
                    {showOldPassword ? (
                      <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Current Keychain password"
              onChange={(event) => setOldPassword(event.target.value)}
              type={showOldPassword ? "text" : "password"}
              value={oldPassword || ""}
            />
          </FormControl>
          <FormControl fullWidth={true} sx={{ mt: 1 }}>
            <InputLabel>New Keychain password</InputLabel>
            <Tooltip
              disableFocusListener={true}
              disableHoverListener={true}
              disableTouchListener={true}
              onClose={() => setSuggestedPassword(undefined)}
              open={!!suggestedPassword}
              placement="top"
              title={
                <Box
                  onClick={(e) => {
                    handlePasswordSuggestionUse(suggestedPassword!);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="caption">
                    Click here to use suggested password:
                    <pre style={{ margin: ".5rem 0" }}>{suggestedPassword}</pre>
                  </Typography>
                </Box>
              }
            >
              <OutlinedInput
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} tabIndex={-1}>
                      {showPassword ? (
                        <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Keychain password"
                onBlur={() =>
                  setTimeout(() => setSuggestedPassword(undefined), 300)
                }
                onChange={(event) => {
                  const newPassword = event.target.value;
                  setPassword(newPassword);
                  if (suggestedPassword) {
                    if (!newPassword.length) {
                      handlePasswordFocus(newPassword);
                    }
                  } else {
                    if (newPassword.trim().length) {
                      setSuggestedPassword(undefined);
                    }
                  }
                }}
                onFocus={() => handlePasswordFocus(password)}
                type={showPassword ? "text" : "password"}
                value={password || ""}
              />
            </Tooltip>
          </FormControl>
          <FormControl fullWidth={true} sx={{ mt: 1 }}>
            <InputLabel>Repeat new Keychain password</InputLabel>
            <OutlinedInput
              autoComplete="new-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowConfirmPassword} tabIndex={-1}>
                    {showConfirmPassword ? (
                      <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Repeat new Keychain password"
              onChange={(event) => setConfirmPassword(event.target.value)}
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword || ""}
            />
          </FormControl>
        </form>
        {!isValid ? (
          <Alert severity="error" sx={{ mt: 2 }}>
            <strong>Invalid password:</strong>
            <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
              {validationErrors?.map((validationError, index) => (
                <li key={index}>{validationError}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={changing} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={changing}
          loadingPosition="start"
          onClick={() => handleSubmit(oldPassword, password, confirmPassword)}
          startIcon={<FontAwesomeIcon icon={faKeySkeletonLeftRight} />}
          variant="contained"
        >
          Change Keychain password
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ChangeKeychainPasswordDialog);
