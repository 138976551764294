import {
  faCheck,
  faFlask,
  faLinkSlash,
} from "@fortawesome/pro-light-svg-icons";
import { faCaretDown as faCaretDownSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material";
import { memo, useCallback } from "react";
import { usePopupState } from "hooks";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "../../../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import usePublisherDataNodeActions from "../../usePublisherDataNodeActions";

type PublisherDataNodeDeprovisionButtonProps = {
  retrieveDatasets: () => Promise<void>;
};

const PublisherDataNodeDeprovisionButton: React.FC<
  PublisherDataNodeDeprovisionButtonProps
> = ({ retrieveDatasets }) => {
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });

  const { isPublisher } = usePublishedMediaInsightsDcr();
  const { publishedDataLab } = useMediaInsightsDcrData();
  const { deprovisionDataLab, deprovisioning } = usePublisherDataNodeActions({
    retrieveDatasets,
  });
  const handleDataLabDeprovision = useCallback(() => {
    deprovisionDataLab(publishedDataLab!);
  }, [deprovisionDataLab, publishedDataLab]);
  if (deprovisioning) {
    return <CircularProgress color="inherit" size={16} />;
  }
  return (
    <>
      <ButtonGroup aria-label="split button" sx={{ ml: 2 }}>
        <Button
          color="primary"
          startIcon={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={isPublisher ? faFlask : faCheck}
              style={{ marginRight: "4px" }}
            />
          }
          style={{
            justifyContent: "flex-start",
            maxWidth: "221px",
            minWidth: "221px",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {publishedDataLab?.name || "Datalab provisioned"}
        </Button>
        <Button
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="menu"
          color="primary"
          onClick={open}
          sx={{ "&.MuiButtonGroup-grouped": { minWidth: "auto" } }}
          variant="outlined"
        >
          <FontAwesomeIcon fixedWidth={true} icon={faCaretDownSolid} />
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        marginThreshold={8}
        onClose={close}
        open={isOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuList
          autoFocusItem={true}
          disablePadding={true}
          id="split-button-menu"
        >
          <MenuItem onClick={handleDataLabDeprovision}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faLinkSlash}
              style={{ marginRight: ".5rem" }}
            />{" "}
            Deprovision datalab
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

PublisherDataNodeDeprovisionButton.displayName =
  "PublisherDataNodeDeprovisionButton";

export default memo(PublisherDataNodeDeprovisionButton);
