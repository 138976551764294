import { MenuItem, Select } from "@mui/material";
import { memo } from "react";
import { DatasetsView } from "features/datasets/models";

type DatasetsViewSelectProps = {
  setDatasetsView: (viewType: DatasetsView) => void;
  selectedDatasetsView: DatasetsView;
};

const datasetsViewTypeMap = new Map<DatasetsView, string>([
  [DatasetsView.All, "All datasets"],
  [DatasetsView.KeychainOnly, "Only selectable datasets"],
]);

// Selector for choosing between all datasets and keychain ones
const DatasetsViewSelect: React.FC<DatasetsViewSelectProps> = ({
  setDatasetsView,
  selectedDatasetsView,
}) => {
  return (
    <Select
      displayEmpty={true}
      onChange={({ target }) => {
        setDatasetsView(target.value as DatasetsView);
      }}
      renderValue={(value) => {
        return datasetsViewTypeMap.get(value) || "Select view...";
      }}
      sx={{ m: 1 }}
      value={selectedDatasetsView}
    >
      {Object.values(DatasetsView).map((viewType: DatasetsView) => (
        <MenuItem key={viewType} value={viewType}>
          {datasetsViewTypeMap.get(viewType)}
        </MenuItem>
      ))}
    </Select>
  );
};

DatasetsViewSelect.displayName = "DatasetsViewSelect";

export default memo(DatasetsViewSelect);
