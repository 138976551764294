import { Box, Typography } from "@mui/material";
import { memo } from "react";
import {
  DataLabDownloadValidationReportButton,
  DataLabStatistics,
  DataLabValidationAlerts,
  DataLabValidationButton,
  useDataLabContext,
} from "features/dataLabs";

const DataLabQualityStatistics: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  return (
    <Box mt={1}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h6">Data quality statistics</Typography>
        <Box display="flex">
          {dataLab?.statistics && <DataLabDownloadValidationReportButton />}
          <DataLabValidationButton />
        </Box>
      </Box>
      <DataLabValidationAlerts />
      {dataLab?.statistics && <DataLabStatistics />}
    </Box>
  );
};

DataLabQualityStatistics.displayName = "DataLabQualityStatistics";

export default memo(DataLabQualityStatistics);
