import { InlineEditor } from "@decentriq/components";
import { TableColumnHashingAlgorithm } from "@decentriq/graphql/dist/types";
import {
  Box,
  Checkbox,
  ListItem,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import { memo } from "react";
import { useDataRoom } from "contexts";
import {
  castPrimitiveTypeToFormatType,
  type DataRoomTableColumn,
} from "models";
import {
  dataRoomTableFormatTypeOptions,
  dataRoomTablePrimitiveTypeOptions,
} from "./DataNodeConstructorModels";

interface TableNodeColumnTileProps {
  column: DataRoomTableColumn;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(even)": {
    background: "whitesmoke",
  },
}));

const TableNodeColumnTile: React.FC<TableNodeColumnTileProps> = ({
  column,
}) => {
  const style = {
    display: "flex",
  };
  const { isPublished } = useDataRoom();
  const { primitiveType, formatType, hashWith } = column;
  return (
    <StyledListItem style={style} sx={{ p: 0.5 }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          gap: 0.5,
          width: 0,
        }}
      >
        <InlineEditor
          fullWidth={true}
          readOnly={true}
          style={{ flex: 1 }}
          value={column.name}
        />
        <Box
          sx={{
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            mb: -0.5,
            mt: -0.5,
          }}
        >
          <Select
            MenuProps={{
              transformOrigin: { horizontal: "center", vertical: "top" },
            }}
            disableUnderline={true}
            disabled={true}
            sx={{
              borderRadius: 0,
              minWidth: 124,
              textAlign: "center",
              width: 240,
            }}
            value={
              !isPublished
                ? formatType || castPrimitiveTypeToFormatType(primitiveType)
                : formatType || primitiveType
            }
            variant="filled"
          >
            {!isPublished || (isPublished && formatType)
              ? dataRoomTableFormatTypeOptions?.map(
                  ({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  )
                )
              : dataRoomTablePrimitiveTypeOptions?.map(
                  ({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
          </Select>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "center",
              minWidth: 184,
              width: 184,
            }}
          >
            <Checkbox
              checked={hashWith === TableColumnHashingAlgorithm.Sha256Hex}
              disabled={true}
            />
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "center",
              minWidth: 180,
              width: 180,
            }}
          >
            <Checkbox checked={column.nullable} disabled={true} />
          </div>
        </Box>
      </Box>
    </StyledListItem>
  );
};

export default memo(TableNodeColumnTile);
