import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  type Theme,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import * as yup from "yup";
import { useKeychain } from "wrappers";

const useInputLabelStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&.Mui-focused": {
      color: theme.palette.background.paper,
    },
    color: theme.palette.background.paper,
  },
}));

const useOutlinedInputStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&:before": {
      borderColor: theme.palette.background.paper,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: theme.palette.background.paper,
    },
    color: theme.palette.background.paper,
  },
}));

const validationSchema = yup.object({
  password: yup.string().trim().required().defined(),
});

type EmptyKeychainFormValues = yup.InferType<typeof validationSchema>;

interface EmptyKeychainDialogProps {
  onCancel: () => void;
  open: boolean;
}

const EmptyKeychainDialog: React.FC<EmptyKeychainDialogProps> = ({
  open,
  onCancel,
}) => {
  const { classes: inputLabelClasses } = useInputLabelStyles();
  const { classes: OutlinedInputClasses } = useOutlinedInputStyles();
  const [emptying, setEmptying] = useState(false);
  const { emptyKeychain } = useKeychain();
  const [password, setPassword] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  const validate = useCallback(
    async (values: EmptyKeychainFormValues): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  useEffect(() => {
    if (password === undefined) {
      return;
    }
    validate({
      password,
    });
  }, [password, validate]);
  const handleSubmit = useCallback(
    async (password: string | undefined) => {
      setEmptying(true);
      const isValid = await validate({
        password: password || "",
      });
      if (isValid) {
        const emptied = await emptyKeychain(password!.trim());
        if (emptied) {
          onCancel();
        }
      }
      setEmptying(false);
    },
    [emptyKeychain, setEmptying, validate, onCancel]
  );
  return (
    <Dialog
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }),
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={emptying ? undefined : onCancel}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to empty your Keychain?</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          All stored keys will be deleted from your Keychain. You will no longer
          be able reprovision any of your existing datasests to other data clean
          rooms.
        </Typography>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          Note: Datasets will remain provisioned to all data clean rooms. No
          data will be lost.
        </Typography>
        <Box sx={{ mt: 1 }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(password);
            }}
          >
            <FormControl fullWidth={true}>
              <InputLabel classes={inputLabelClasses}>
                Keychain password
              </InputLabel>
              <OutlinedInput
                classes={OutlinedInputClasses}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FontAwesomeIcon
                          color="white"
                          fixedWidth={true}
                          icon={faEyeSlash}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color="white"
                          fixedWidth={true}
                          icon={faEye}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(event) => setPassword(event.target.value)}
                type={showPassword ? "text" : "password"}
                value={password || ""}
              />
            </FormControl>
          </form>
        </Box>
        {!isValid ? (
          <Alert severity="error" sx={{ mt: 1 }}>
            <strong>Invalid password:</strong>
            <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
              {validationErrors?.map((validationError, index) => (
                <li key={index}>{validationError}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={emptying} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="inherit"
          loading={emptying}
          loadingPosition="start"
          onClick={() => handleSubmit(password)}
          startIcon={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangleRegular}
            />
          }
          sx={{ color: "error.main" }}
          variant="contained"
        >
          Empty Keychain
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

EmptyKeychainDialog.displayName = "EmptyKeychainDialog";

export default memo(EmptyKeychainDialog);
