import {
  Checkbox,
  type CheckboxProps,
  FormControlLabel,
  type FormControlLabelProps,
  styled,
  type Theme,
  Tooltip,
  type TooltipProps,
} from "@mui/material";
import { Fragment } from "react";

type CheckboxComponentProps = {
  label?: React.ReactNode;
  formControlLabelProps?: FormControlLabelProps;
  tooltip?: string | null | undefined;
} & CheckboxProps;

const StyledCheckbox = styled(Checkbox)<CheckboxComponentProps & Theme>(
  ({ theme }) => ({
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
    margin: theme.spacing(0.25, 0.25, 0.25, 0),
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
  })
);

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  label: LabelComponent,
  checked,
  className,
  disabled,
  onChange,
  size,
  sx = {},
  formControlLabelProps: { control, label, ...restFormControlLabelProps } = {},
  tooltip,
  ...restCheckboxProps
}): JSX.Element => {
  const CheckboxWrapper = tooltip ? Tooltip : Fragment;
  const wrapperProps: TooltipProps | {} = tooltip
    ? { placement: "top", title: tooltip }
    : {};
  return (
    <CheckboxWrapper {...wrapperProps}>
      <FormControlLabel
        className={className}
        control={
          control || (
            <StyledCheckbox
              checked={checked}
              disabled={disabled}
              onChange={onChange}
              size={size}
              {...restCheckboxProps}
            />
          )
        }
        label={LabelComponent}
        sx={sx}
        {...restFormControlLabelProps}
      />
    </CheckboxWrapper>
  );
};

CheckboxComponent.displayName = "CheckboxComponent";

export default CheckboxComponent;
