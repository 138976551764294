import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Button,
  createTheme,
  Popover,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Chip } from "components";
import { useComputeNodesVars } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useIsComputeEnabled,
  useNodes,
} from "hooks";
import { type HooksNode } from "hooks/useNodes/useNodes";
import usePopupState from "hooks/usePopupState/usePopupState";
import {
  WORKER_TYPE_COLOR,
  workerTypeShortNameToDraftComputeNodeTypeName,
} from "models";
import { WORKER_TYPE_LABEL } from "../ComputeNodeType/useComputeNodeType";
import useComputeNodeCreateMutation from "./useComputeNodeCreate/useComputeNodeCreate";

export const PLACEHOLDER: Record<WorkerTypeShortName, string> = {
  [WorkerTypeShortName.Match]: "new Matching computation",
  [WorkerTypeShortName.Post]: "new Post computation",
  [WorkerTypeShortName.Preview]: "new Airlock computation",
  [WorkerTypeShortName.S3]: "new S3 integration",
  [WorkerTypeShortName.Python]: "new Python computation",
  [WorkerTypeShortName.R]: "new R computation",
  [WorkerTypeShortName.Sql]: "new NATIVE-SQL computation",
  [WorkerTypeShortName.Sqlite]: "new SQL computation",
  [WorkerTypeShortName.Synthetic]: "new Synthetic Data computation",
};

interface ComputeNodeCreatorProps {
  dataRoomId: string;
  onComputationCreate?: (
    computationName: string,
    computationType: WorkerTypeShortName
  ) => void;
  excludeComputationTypes?: WorkerTypeShortName[];
}

const ComputeNodeCreator = ({
  dataRoomId,
  onComputationCreate,
  excludeComputationTypes = [],
}: ComputeNodeCreatorProps) => {
  const { expand } = useComputeNodesVars();
  const { nodes } = useNodes();
  // TODO remove this and use the updated theme when available
  const defaultTheme = createTheme();

  const generateName = useCallback(
    (computationType: WorkerTypeShortName) => {
      const filteredNodes = nodes.filter(
        ({ __typename, name }) =>
          __typename ===
            workerTypeShortNameToDraftComputeNodeTypeName.get(
              computationType
            ) && name.includes(WORKER_TYPE_LABEL[computationType])
      );

      let helper = 1;
      const isNameTaken = (elements: HooksNode) =>
        elements.name.includes(
          `${WORKER_TYPE_LABEL[computationType]} ${helper}`
        );

      while (filteredNodes.some(isNameTaken)) {
        helper++;
      }

      return `${WORKER_TYPE_LABEL[computationType]} ${helper}`;
    },
    [nodes]
  );

  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });

  const anchorRef = useRef<HTMLButtonElement>(null);

  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    isMatchComputeEnabled,
    isPostComputeEnabled,
    isPreviewComputeEnabled,
    isS3ComputeEnabled,
    isSqlComputeEnabled,
    isSqliteComputeEnabled,
    isPythonComputeEnabled,
    isRComputeEnabled,
    isSyntheticComputeEnabled,
  } = useIsComputeEnabled();
  const isMatchEnabled =
    isMatchComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Match);
  const isS3Enabled =
    isS3ComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.S3);
  const isPostEnabled =
    isPostComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Post);
  const isPreviewEnabled =
    isPreviewComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Preview);
  const isSqlEnabled =
    isSqlComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Sql);
  const isSqliteEnabled =
    isSqliteComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Sqlite);
  const isPythonEnabled =
    isPythonComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Python);
  const isREnabled =
    isRComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.R);
  const isSyntheticEnabled =
    isSyntheticComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Synthetic);

  const createComputeNodeMutation = useComputeNodeCreateMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be created."
        )
      );
    },
    // TODO: write to cache using a fragment depending on typename
    refetchQueries: ["DraftDataRoomNodes"],
  });

  const createComputation = useCallback(
    (newComputationType: WorkerTypeShortName) => {
      const computationName = generateName(newComputationType);
      if (onComputationCreate) {
        onComputationCreate(computationName, newComputationType);
        close();
        return;
      } else {
        const computeNodeId = uuidv4();

        createComputeNodeMutation(newComputationType, {
          onCompleted: () => {
            expand(computeNodeId);
            close();
          },
          variables: {
            input: {
              draftDataRoomId: dataRoomId,
              id: computeNodeId,
              name: computationName,
            },
          },
        });
      }
    },
    [
      close,
      createComputeNodeMutation,
      dataRoomId,
      expand,
      generateName,
      onComputationCreate,
    ]
  );

  const COMPUTATION_TYPE_BUTTONS = useMemo(
    () => [
      {
        isEnabled: isSqlEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Sql],
        value: WorkerTypeShortName.Sql,
      },
      {
        isEnabled: isSqliteEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Sqlite],
        value: WorkerTypeShortName.Sqlite,
      },
      {
        isEnabled: isMatchEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Match],
        value: WorkerTypeShortName.Match,
      },
      {
        isEnabled: isS3Enabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.S3],
        value: WorkerTypeShortName.S3,
      },
      {
        isEnabled: isPostEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Post],
        value: WorkerTypeShortName.Post,
      },
      {
        isEnabled: isPreviewEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Preview],
        value: WorkerTypeShortName.Preview,
      },
      {
        isEnabled: isPythonEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Python],
        value: WorkerTypeShortName.Python,
      },
      {
        isEnabled: isREnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.R],
        value: WorkerTypeShortName.R,
      },
      {
        isEnabled: isSyntheticEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Synthetic],
        value: WorkerTypeShortName.Synthetic,
      },
    ],
    [
      isSqlEnabled,
      isSqliteEnabled,
      isMatchEnabled,
      isS3Enabled,
      isPostEnabled,
      isPreviewEnabled,
      isPythonEnabled,
      isREnabled,
      isSyntheticEnabled,
    ]
  );

  const isCreateComputationEnabled = COMPUTATION_TYPE_BUTTONS.some(
    ({ isEnabled }) => isEnabled
  );

  return isCreateComputationEnabled ? (
    <Box sx={{ marginBottom: 2 }}>
      <Button
        aria-controls={isOpen ? "add_computation_dropdown" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        color="primary"
        data-testid={testIds.computeNode.computeNodeCreator.newComputation}
        id="add_computation_dropdown"
        onClick={open}
        ref={anchorRef}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faPlusRegular} />}
        variant="contained"
      >
        Add computation
      </Button>
      <ThemeProvider theme={defaultTheme}>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          onClose={close}
          open={isOpen}
          sx={{
            minWidth: "210px",
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
        >
          <Box
            sx={{
              border: "none",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginLeft: 2,
              marginRight: 6,
              marginY: 2,
            }}
          >
            {COMPUTATION_TYPE_BUTTONS.map(
              ({ isEnabled, label, value }) =>
                isEnabled && (
                  <Chip
                    data-testid={`${
                      testIds.computeNode.computeNodeToggle.helper
                    }${label.toLowerCase()}`}
                    key={value}
                    label={label}
                    onClick={() => createComputation(value)}
                    sx={{
                      backgroundColor: WORKER_TYPE_COLOR[value],
                    }}
                  />
                )
            )}
          </Box>
        </Popover>
      </ThemeProvider>
    </Box>
  ) : (
    <Alert severity="warning" sx={{ mb: 1 }}>
      You have no permissions to create new computations
    </Alert>
  );
};

ComputeNodeCreator.displayName = "ComputeNodeCreator";

export default ComputeNodeCreator;
