import { Backdrop, Box, CircularProgress, type Theme } from "@mui/material";
import React, { memo } from "react";
import { Navigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  DataLabErrorReport,
  DataLabNodes,
  DataLabQualityStatistics,
  DataLabToolbar,
} from "features/dataLabs";
import { DataLabWrapper, useDataLabContext } from "features/dataLabs/contexts";

const useBackdropStyles = makeStyles()((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

interface DataLabProps {
  id: string;
}

const DataLab: React.FC<DataLabProps> = ({ id }) => {
  const { classes: backdropClasses } = useBackdropStyles();
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  if (loading) {
    return (
      <Backdrop classes={backdropClasses} invisible={true} open={loading}>
        <CircularProgress color="inherit" size="2.5rem" thickness={1} />
      </Backdrop>
    );
  }
  if (data) {
    return (
      <>
        <DataLabToolbar id={id} />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflow: "auto",
              padding: (theme) => theme.spacing(2.5),
            }}
          >
            <DataLabNodes />
            <DataLabQualityStatistics />
          </Box>
        </Box>
      </>
    );
  }
  return <Navigate replace={true} to="/publisherportal/datalabs" />;
};

const DataLabPageWrapper: React.FC<DataLabProps> = memo(({ id }) => {
  return (
    <DataLabErrorReport id={id}>
      <DataLabWrapper id={id}>
        <DataLab id={id} />
      </DataLabWrapper>
    </DataLabErrorReport>
  );
});

export default DataLabPageWrapper;
