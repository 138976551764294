import { faGear, faKey, faShield } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ExternalConnectionActionsWrapper } from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "../../../../types";

const snowflakeFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    databaseName: yup.string().trim().required("Database name is required"),
    schemaName: yup.string().trim().required("Schema name is required"),
    stageName: yup.string().trim().required("Stage name is required"),
    tableName: yup.string().trim().required("Table name is required"),
    warehouseName: yup.string().trim().required("Warehouse name is required"),
  }),
  credentials: yup.object({
    accountId: yup.string().trim().required("Account ID is required"),
    password: yup.string().trim().required("Password is required"),
    role: yup.string().trim().required("Role is required"),
    username: yup.string().trim().required("Username is required"),
  }),
  datasetName: yup.string(),
});

type SnowflakeFormValues = yup.InferType<typeof snowflakeFormValidationSchema>;

const SnowflakeForm: React.FC<
  ImportExternalDataFormProps & {
    ActionsWrapper?: React.ComponentType;
    FormWrapper?: React.ComponentType;
  }
> = ({
  onCancel,
  onSubmit,
  ActionsWrapper = ExternalConnectionActionsWrapper,
  FormWrapper = Box,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        databaseName: "",
        schemaName: "",
        stageName: "",
        tableName: "",
        warehouseName: "",
      },
      credentials: {
        accountId: "",
        password: "",
        role: "",
        username: "",
      },
      datasetName: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(snowflakeFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: SnowflakeFormValues) => {
      const {
        configuration = {},
        credentials = {},
        datasetName = "",
      } = formValues;
      onSubmit({
        input: {
          datasetName: datasetName.trim() || configuration?.tableName?.trim(),
          snowflake: {
            credentials,
            sourceConfig: configuration,
          },
        },
      });
      reset();
    },
    [reset, onSubmit]
  );

  return (
    <>
      <FormWrapper>
        <form>
          <Stack spacing={1}>
            <Typography color="inherit">
              <FontAwesomeIcon
                icon={faGear}
                style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
              />
              Configuration
            </Typography>
            <Controller
              control={control}
              name="configuration.warehouseName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.warehouseName;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Warehouse name</InputLabel>
                    <OutlinedInput
                      label="Warehouse name"
                      placeholder="Example: DEV_WAREHOUSE"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.databaseName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.databaseName;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Database name</InputLabel>
                    <OutlinedInput
                      label="Database name"
                      placeholder="Example: TEST_DATABASE"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.schemaName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.schemaName;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Schema name</InputLabel>
                    <OutlinedInput
                      label="Schema name"
                      placeholder="Example: TEST_SCHEMA"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.tableName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.tableName;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Table name</InputLabel>
                    <OutlinedInput
                      label="Table name"
                      placeholder="Example: DATA_TABLE"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.stageName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.stageName;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Stage name</InputLabel>
                    <OutlinedInput
                      label="Stage name"
                      placeholder="Example: my_int_stage"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="datasetName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.datasetName;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Stored dataset name (optional)</InputLabel>
                    <OutlinedInput
                      label="Stored dataset name (optional)"
                      placeholder="Example: decentriq_dataset_name"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Typography color="inherit">
              <FontAwesomeIcon
                icon={faKey}
                style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
              />
              Credentials
              <Tooltip
                disableFocusListener={true}
                disableTouchListener={true}
                placement="top-start"
                title="Credentials are encrypted and utilized only once by the enclave."
              >
                <FontAwesomeIcon
                  color={grey[600]}
                  icon={faShield}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Tooltip>
            </Typography>
            <Controller
              control={control}
              name="credentials.accountId"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.accountId;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Account ID</InputLabel>
                    <OutlinedInput
                      label="Account ID"
                      placeholder="Example: sdkreui-mt37045"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="credentials.role"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.role;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Role</InputLabel>
                    <OutlinedInput
                      label="Role"
                      placeholder="Example: PUBLIC"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="credentials.username"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.username;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Username</InputLabel>
                    <OutlinedInput
                      label="Username"
                      placeholder="Example: testuser"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="credentials.password"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.password;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                      autoComplete="off"
                      label="Password"
                      placeholder="Example: Cfw3?F^K46m(fj"
                      type="password"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </Stack>
        </form>
      </FormWrapper>
      <ActionsWrapper>
        <Button
          color="inherit"
          onClick={handlePreviousStepClick}
          variant="text"
        >
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Import
        </Button>
      </ActionsWrapper>
    </>
  );
};

SnowflakeForm.displayName = "SnowflakeForm";

export default memo(SnowflakeForm);
