import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const S3BucketFormConfigurationFields: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="configuration.url"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.configuration?.url;
          return (
            <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
              <InputLabel>S3 Bucket name</InputLabel>
              <OutlinedInput
                label="S3 Bucket name"
                placeholder="Example: my-bucket-name"
                {...field}
              />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="configuration.region"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.configuration?.region;
          return (
            <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
              <InputLabel>Region code</InputLabel>
              <OutlinedInput
                label="Region code"
                placeholder="Example: eu-central-2"
                {...field}
              />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
};

S3BucketFormConfigurationFields.displayName = "S3BucketFormConfigurationFields";

export default memo(S3BucketFormConfigurationFields);
