import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "../../../../types";

type AzureFormProps = (ImportExternalDataFormProps | ExportDatasetFormProps) & {
  type: ExternalConnectionType;
  ActionsWrapper?: React.ComponentType;
  FormWrapper?: React.ComponentType;
};

const AzureFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    blobName: yup.string().trim().required("Blob name is required"),
    storageAccount: yup.string().trim().required("Storage account is required"),
    storageContainer: yup
      .string()
      .trim()
      .required("Storage container is required"),
  }),
  credentials: yup.object({
    sasToken: yup.string().trim().required("SAS token is required"),
  }),
  datasetName: yup.string(),
});

type AzureFormValues = yup.InferType<typeof AzureFormValidationSchema>;

const AzureForm: React.FC<AzureFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText = "Import",
  defaultValues,
  ActionsWrapper = ExternalConnectionActionsWrapper,
  FormWrapper = Box,
  type = ExternalConnectionType.IMPORT,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        blobName:
          type === ExternalConnectionType.IMPORT
            ? ""
            : defaultValues?.datasetName || "",
        storageAccount: "",
        storageContainer: "",
      },
      credentials: {
        sasToken: "",
      },
      ...(type === ExternalConnectionType.IMPORT
        ? { datasetName: defaultValues?.datasetName || "" }
        : {}),
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(AzureFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: AzureFormValues) => {
      const {
        configuration: {
          blobName = "",
          storageAccount = "",
          storageContainer = "",
        } = {},
        credentials: { sasToken = "" } = {},
        datasetName = "",
      } = formValues;

      onSubmit({
        input: {
          azure: {
            credentials: {
              blobName: blobName.trim(),
              sasToken: sasToken.trim(),
              storageAccount: storageAccount.trim(),
              storageContainer: storageContainer.trim(),
            },
          },
          ...(type === ExternalConnectionType.IMPORT
            ? { datasetName: datasetName?.trim() || blobName?.trim() }
            : {}),
        },
      });
      reset();
    },
    [reset, onSubmit, type]
  );

  return (
    <>
      <FormWrapper>
        <form>
          <Stack spacing={1}>
            <ExternalConnectionConfigurationLabel />
            <Controller
              control={control}
              name="configuration.storageAccount"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.storageAccount;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Storage account</InputLabel>
                    <OutlinedInput
                      label="Storage account"
                      placeholder="Example: myazureaccount"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.storageContainer"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.storageContainer;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Storage container</InputLabel>
                    <OutlinedInput
                      label="Storage container"
                      placeholder="Example: my-container"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.blobName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.blobName;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>Blob name</InputLabel>
                    <OutlinedInput
                      label="Blob name"
                      placeholder="Example: my-file.csv"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            {type === ExternalConnectionType.IMPORT && (
              <Controller
                control={control}
                name="datasetName"
                render={({ field, formState }) => {
                  const { errors } = formState;
                  const fieldError = errors?.datasetName;
                  return (
                    <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                      <InputLabel>Stored dataset name (optional)</InputLabel>
                      <OutlinedInput
                        label="Stored dataset name (optional)"
                        placeholder="Example: DQ_dataset_name"
                        {...field}
                      />
                      <FormHelperText>{fieldError?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            )}
            <ExternalConnectionCredentialsLabel />
            <Controller
              control={control}
              name="credentials.sasToken"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.sasToken;
                return (
                  <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
                    <InputLabel>SAS Token</InputLabel>
                    <OutlinedInput
                      label="SAS Token"
                      placeholder="Example: sp=racw&st=2023-08-02T10:27:33Z..."
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </Stack>
        </form>
      </FormWrapper>
      <ActionsWrapper>
        <Button
          color="inherit"
          onClick={handlePreviousStepClick}
          variant="text"
        >
          Back
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          {submitButtonText}
        </Button>
      </ActionsWrapper>
    </>
  );
};

AzureForm.displayName = "AzureForm";

export default memo(AzureForm);
