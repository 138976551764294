import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { faFileContract as faFileContractRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material";
import { memo, useMemo, useRef, useState } from "react";
import { usePopupState } from "hooks";

interface SubmitForApprovalButtonProps {
  disabled: boolean;
  loading: boolean;
  onClick: (validate: boolean) => void;
}

const SubmitForApprovalButton: React.FC<SubmitForApprovalButtonProps> = ({
  onClick,
  disabled,
  loading,
}) => {
  const options = useMemo<{ label: string }[]>(
    () => [
      {
        label: "Submit for approval",
      },
      {
        label: "Submit for approval without validation",
      },
    ],
    []
  );
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleClick = () => onClick(selectedIndex === 0);
  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    close();
    setSelectedIndex(index);
  };
  return (
    <div style={{ display: "flex" }}>
      <ButtonGroup aria-label="split button" ref={anchorRef}>
        <Button
          color="inherit"
          disabled={disabled || loading}
          onClick={handleClick}
          startIcon={<FontAwesomeIcon icon={faFileContractRegular} />}
          style={{ paddingLeft: "16px" }}
        >
          {options[selectedIndex].label}
        </Button>
        <Button
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="menu"
          color="inherit"
          onClick={open}
          sx={{ "&.MuiButtonGroup-grouped": { minWidth: "auto" } }}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faChevronDown} />
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        marginThreshold={8}
        onClose={close}
        open={isOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuList autoFocusItem={true} id="split-button-menu">
          {options.map(({ label }, index) => {
            if (index === selectedIndex) {
              return null;
            }
            return (
              <MenuItem
                key={label}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </div>
  );
};

SubmitForApprovalButton.displayName = "SubmitForApprovalButton";

export default memo(SubmitForApprovalButton);
