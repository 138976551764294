import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";

interface ConfigurationDeleteDialogProps {
  title: string;
  description: string;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationTestId?: string;
}

const ConfigurationDeleteDialog: React.FC<ConfigurationDeleteDialogProps> = ({
  open,
  loading,
  onCancel,
  onConfirm,
  title,
  description,
  confirmationTestId,
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={!loading ? onCancel : () => {}}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>{title}</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          color="error"
          data-testid={confirmationTestId}
          loading={loading}
          loadingPosition="start"
          onClick={onConfirm}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfigurationDeleteDialog.displayName = "ConfigurationDeleteDialog";

export default memo(ConfigurationDeleteDialog);
