import { Alert } from "@mui/material";
import { memo } from "react";
import { useDataLabContext } from "features/dataLabs";

const DataLabValidationAlerts: React.FC = () => {
  const {
    dataLab: { data: dataLab },
    computationError,
    datasetValidationErrors,
  } = useDataLabContext();

  if (datasetValidationErrors?.size > 0) {
    return (
      <Alert severity="error" sx={{ marginBottom: 1 }}>
        One or more datasets have failed validation
      </Alert>
    );
  }

  if (dataLab?.statistics?.errors?.length > 0) {
    return (
      <Alert severity="error" sx={{ marginBottom: 1 }}>
        Data quality issue
        <ul style={{ margin: 0, paddingLeft: "32px" }}>
          {dataLab?.statistics?.errors.map((error: string) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </Alert>
    );
  }

  if (computationError?.originalError) {
    return (
      <Alert severity="error" sx={{ marginBottom: 1 }}>
        Statistics failed to compute, please retry. If the problem persists,
        contact support.
      </Alert>
    );
  }

  if (dataLab?.statistics && datasetValidationErrors?.size === 0) {
    return (
      <Alert severity="success" sx={{ marginBottom: 1 }}>
        No blocking issues have been detected. This datalab can now be used in
        Media DCRs.
      </Alert>
    );
  }

  return null;
};

DataLabValidationAlerts.displayName = "DataLabValidationAlerts";

export default memo(DataLabValidationAlerts);
