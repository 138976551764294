import { alpha, FormControlLabel, styled, Switch } from "@mui/material";
import { brown, indigo, teal } from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useMatchNodeDataSources } from "features/computeNode/components/ComputeNodeEditor/components/MatchComputeNodeEditor/hooks";
import { ResultStatisticsBlock } from "./components";

// NOTE: Replace with the `color="primary"` prop once the theme is updated
const GreenishSwitch = styled(Switch)(({ theme: { palette } }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    "&:hover": {
      backgroundColor: alpha(palette.primary.main, palette.action.hoverOpacity),
    },
    color: palette.primary.main,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: palette.primary.main,
  },
}));

const datasetDataKeySuffix = "_statistics.csv";
const resultsDataKeyName = `results${datasetDataKeySuffix}`;
const transformDataIntoStats = (data: any) => {
  return data?.reduce((stats: any, row: any) => {
    const [statId, statName, statValue] = row;
    if (statId === "" && statName === "Number of rows") {
      stats.rows = Number(statValue);
    }
    if (statName === "Number of unique keys") {
      stats.uniqueValues = [
        ...(stats.uniqueValues || []),
        { id: statId, value: Number(statValue) },
      ];
    }
    if (statName === "Number of compound keys without NULLs") {
      stats.rowsWithoutEmptyValuesByGroup = [
        ...(stats.rowsWithoutEmptyValuesByGroup || []),
        { id: statId, value: Number(statValue) },
      ];
    }
    if (statId === "" && statName === "Approx. number of rows") {
      stats.rows = Number(statValue);
    }
    if (
      statName === "Approx. number of result rows matched by this condition"
    ) {
      stats.approxRowsMatchedByGroup = [
        ...(stats.approxRowsMatchedByGroup || []),
        { id: statId, value: Number(statValue) },
      ];
    }
    return stats;
  }, {});
};

interface ResultStatisticsProps {
  [key: string]: any;
}

const ResultStatistics: React.FC<ResultStatisticsProps> = ({
  id,
  data = {},
}) => {
  // NOTE:
  // Seems to be an overkill to use match node data sources hook here to get the order of the data sources.
  // The previous solution was to simply get the names of all statistics CSV with no guarantee that the order is correct:
  // const [leftDatasetDataKeyName, rightDatasetDataKeyName] = Object.keys(data).filter((key) => key !== resultsDataKeyName);
  const {
    availableDataSources,
    selectedLeftDataSourceNodeId,
    selectedRightDataSourceNodeId,
  } = useMatchNodeDataSources(id);
  const leftDatasetDataKeyName = `${
    availableDataSources.find(({ id }) => id === selectedLeftDataSourceNodeId)
      ?.name
  }${datasetDataKeySuffix}`.toLowerCase();
  const rightDatasetDataKeyName = `${
    availableDataSources.find(({ id }) => id === selectedRightDataSourceNodeId)
      ?.name
  }${datasetDataKeySuffix}`.toLowerCase();
  const leftDatasetData = data[leftDatasetDataKeyName];
  const rightDatasetData = data[rightDatasetDataKeyName];
  const resultsData = data[resultsDataKeyName];
  const leftDatasetStatistics = transformDataIntoStats(leftDatasetData) || {};
  const rightDatasetStatistics = transformDataIntoStats(rightDatasetData) || {};
  const resultsStatistics = transformDataIntoStats(resultsData) || {};
  const [useCommonDenominator, setUseCommonDenominator] = useState(true);
  const onScaleEachBoxIndependentlyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseCommonDenominator(!event.target.checked);
  };
  const numbers = [
    leftDatasetStatistics,
    rightDatasetStatistics,
    resultsStatistics,
  ].reduce((memo, statistics) => {
    return [
      ...memo,
      ...[
        "rowsWithoutEmptyValuesByGroup",
        "uniqueValues",
        "approxRowsMatchedByGroup",
      ].reduce((memo, key) => {
        return [
          ...memo,
          ...((statistics?.[key] &&
            statistics?.[key]?.length > 1 &&
            statistics?.[key]?.map(({ value }: { value: number }) => value)) ||
            []),
        ];
      }, [] as number[]),
      statistics?.rows || 0,
    ];
  }, [] as number[]);
  const denominator = useCommonDenominator ? Math.max(...numbers) : undefined;
  return (
    <Grid container={true} spacing={2}>
      <Grid xs={12}>
        <FormControlLabel
          control={
            <GreenishSwitch
              checked={!useCommonDenominator}
              color="primary"
              onChange={onScaleEachBoxIndependentlyChange}
            />
          }
          label="Scale each box independently"
        />
      </Grid>
      <Grid xs={12}>
        <ResultStatisticsBlock
          color={teal}
          title={leftDatasetDataKeyName?.replace(datasetDataKeySuffix, "")}
          {...leftDatasetStatistics}
          denominator={denominator}
          rowsLabel="Rows"
        />
      </Grid>
      <Grid xs={12}>
        <ResultStatisticsBlock
          color={indigo}
          title="Matching result"
          {...resultsStatistics}
          denominator={denominator}
          rowsLabel="Approx. rows"
        />
      </Grid>
      <Grid xs={12}>
        <ResultStatisticsBlock
          color={brown}
          title={rightDatasetDataKeyName?.replace(datasetDataKeySuffix, "")}
          {...rightDatasetStatistics}
          denominator={denominator}
          rowsLabel="Rows"
        />
      </Grid>
    </Grid>
  );
};
ResultStatistics.displayName = "ResultStatistics";

export default ResultStatistics;
