import { useAuth0 } from "@auth0/auth0-react";
import { Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback, useEffect, useState } from "react";
import { DataScienceDataRoomCreateDialog } from "features/dataScienceDataRoom";
import { MeasurementDcrCreateDialog } from "features/measurementDcr";
import { MediaInsightsDcrCreateDialog } from "features/MediaInsightsDcr";
import { useCreateDataRoom, useOrganizationPreferences } from "hooks";
import { DataRoomType } from "models";
import DataRoomTypeSelectDialog from "../DataRoomTypeSelectDialog/DataRoomTypeSelectDialog";

interface DataRoomCreateDialogProps {
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DataRoomCreateDialog: React.FC<DataRoomCreateDialogProps> = memo(
  ({ renderTrigger }) => {
    const [
      isTypeSelectionOpen,
      { setTrue: openTypeSelection, setFalse: closeTypeSelection },
    ] = useBoolean(false);
    const { user = {} } = useAuth0();
    const { email: currentUserEmail = "" } = user || {};
    const [dataRoomType, setDataRoomType] = useState<DataRoomType | null>(null);
    const {
      createDataRoom: createDataScienceDataRoom,
      isCreateDataRoomLoading: isCreateDataScienceDataRoomLoading,
    } = useCreateDataRoom(currentUserEmail, {
      onCompleted: () => setDataRoomType(null),
      onError: () => setDataRoomType(null),
    });

    const {
      isAllowedToCreateDataRoom,
      hasAnalyticsFeatures: canViewAnalyticsDataRoom,
      canViewMediaDataRoom,
      canViewMeasurements: canViewMeasurementDataRoom,
      numberOfAvailableDataRoomTypes,
    } = useOrganizationPreferences();

    // Open proper modal window depending on allowance selected in Admin for a specific organization
    useEffect(() => {
      if (!isTypeSelectionOpen || !isAllowedToCreateDataRoom) {
        closeTypeSelection();
        return;
      }
      if (
        canViewAnalyticsDataRoom &&
        !(canViewMediaDataRoom || canViewMeasurementDataRoom)
      ) {
        setDataRoomType(DataRoomType.DataScience);
        closeTypeSelection();
      }
      if (
        canViewMediaDataRoom &&
        !(canViewAnalyticsDataRoom || canViewMeasurementDataRoom)
      ) {
        setDataRoomType(DataRoomType.MediaInsights);
        closeTypeSelection();
      }
      if (
        canViewMeasurementDataRoom &&
        !(canViewAnalyticsDataRoom || canViewMediaDataRoom)
      ) {
        setDataRoomType(DataRoomType.Measurement);
        closeTypeSelection();
      }
    }, [
      isTypeSelectionOpen,
      closeTypeSelection,
      isAllowedToCreateDataRoom,
      canViewAnalyticsDataRoom,
      canViewMediaDataRoom,
      canViewMeasurementDataRoom,
    ]);
    const handleDataRoomTypeSetting = useCallback(
      (type: DataRoomType | null) => {
        setDataRoomType(type);
        if (type !== null) {
          closeTypeSelection();
        }
      },
      [setDataRoomType, closeTypeSelection]
    );
    const handleCancel = useCallback(
      () => handleDataRoomTypeSetting(null),
      [handleDataRoomTypeSetting]
    );
    const restartCreation = useCallback(() => {
      handleCancel();
      if (numberOfAvailableDataRoomTypes < 2) {
        return;
      }
      openTypeSelection();
    }, [handleCancel, numberOfAvailableDataRoomTypes, openTypeSelection]);
    return (
      <>
        {renderTrigger(openTypeSelection)}
        <Portal>
          <>
            <DataRoomTypeSelectDialog
              onCancel={closeTypeSelection}
              onConfirm={handleDataRoomTypeSetting}
              open={isTypeSelectionOpen}
            />
            <DataScienceDataRoomCreateDialog
              loading={isCreateDataScienceDataRoomLoading}
              onCancel={handleCancel}
              onConfirm={createDataScienceDataRoom}
              open={dataRoomType === DataRoomType.DataScience}
              restartCreation={restartCreation}
            />
            <MediaInsightsDcrCreateDialog
              onCancel={handleCancel}
              open={dataRoomType === DataRoomType.MediaInsights}
              restartCreation={restartCreation}
            />
            <MeasurementDcrCreateDialog
              onCancel={handleCancel}
              open={dataRoomType === DataRoomType.Measurement}
              restartCreation={restartCreation}
            />
          </>
        </Portal>
      </>
    );
  }
);

export default DataRoomCreateDialog;
