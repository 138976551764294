import { InfoTooltip } from "@decentriq/components";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { Checkbox } from "components";
import {
  ExcludeSeedAudienceInfoDialog,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

const ExcludeSeedAudienceCheckbox: React.FC = () => {
  const {
    setExcludeSeedAudience,
    activatedLookalikeAudiences,
    excludeSeedAudience,
  } = useAdvertiserAudienceGenerator();
  return (
    <>
      <Box mt={4} width="fit-content">
        <Checkbox
          checked={excludeSeedAudience}
          disabled={!!activatedLookalikeAudiences.length}
          label={
            <Typography>
              Exclude seed audience from your new audience
              <InfoTooltip
                tooltip="Checking this box will remove any individuals in your data
                from the generated audience. For privacy reasons, all
                audiences shared with a publisher must be of the same type
                (excluded / included)."
              />
            </Typography>
          }
          onChange={(_, checked) => setExcludeSeedAudience(checked)}
          sx={{ ml: 0, width: "fit-content" }}
        />
      </Box>
      <ExcludeSeedAudienceInfoDialog />
    </>
  );
};

ExcludeSeedAudienceCheckbox.displayName = "ExcludeSeedAudienceCheckbox";

export default memo(ExcludeSeedAudienceCheckbox);
