import { InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { memo } from "react";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { type DataType } from "models";

interface DatasetConstraintsProps {
  id: string;
  readOnly?: boolean;
  disabled?: boolean;
  value?: boolean;
  dataType: DataType;
}

const DatasetConstraints: React.FC<DatasetConstraintsProps> = memo(
  ({ id, disabled, readOnly, value, dataType }) => {
    const { handleConstraintUpdate } = useDataNodeActions();
    const Label = (
      <span>
        Dataset required
        <InfoTooltip
          tooltip={`Computations which depend on this ${
            dataType === "table" ? "table" : "file"
          } will fail unless a dataset has
        been provisioned to it.`}
          top="0px"
        />
      </span>
    );
    if (readOnly && !value) return null;
    return (
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              color="primary"
              data-testid={
                testIds.computeNode.computeNodeEditor.datasetRequired
              }
              disabled={readOnly || disabled}
              onChange={(_, isRequired) => {
                handleConstraintUpdate({
                  dataType,
                  id,
                  isRequired,
                });
              }}
            />
          }
          label={Label}
          style={{ margin: 0 }}
        />
      </Box>
    );
  }
);

DatasetConstraints.displayName = "DatasetConstraints";

export default DatasetConstraints;
