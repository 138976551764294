import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExternalConnectionCredentialsLabel } from "features/datasets/components/ExternalConnections";

const S3BucketFormCredentials: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      <ExternalConnectionCredentialsLabel />
      <Controller
        control={control}
        name="credentials.accessKey"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.credentials?.accessKey;
          return (
            <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
              <InputLabel>Access Key</InputLabel>
              <OutlinedInput
                label="Access Key"
                placeholder="Example: 5JG8C6HNDPQF7W2X3Y1Z"
                {...field}
              />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="credentials.secretKey"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.credentials?.secretKey;
          return (
            <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
              <InputLabel>Secret Key</InputLabel>
              <OutlinedInput
                autoComplete="off"
                label="Secret Key"
                placeholder="Example: F57yT3QaX2nD8jHr4KlP9cVs1W6zU0E2sY8hN4bM"
                {...field}
              />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
};

S3BucketFormCredentials.displayName = "S3BucketFormCredentials";

export default memo(S3BucketFormCredentials);
