import { testIds } from "@decentriq/utils";
import { FormControl, Grid, OutlinedInput, Typography } from "@mui/material";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

// TODO: this component needs to be unified with the one in MediaInsightsDcrCreateCollaborationConfigurationStep
const DataRoomName: React.FC = () => {
  const { control } = useFormContext();
  return (
    <Grid container={true} item={true} spacing={1}>
      <Grid item={true} sx={{ pb: 0 }} xs={12}>
        <Typography fontWeight="fontWeightSemiBold" variant="body1">
          1. Data clean details
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <Controller
          control={control}
          name="dataRoomName"
          render={({ field }) => {
            return (
              <FormControl
                fullWidth={true}
                sx={{ height: "100%", position: "relative" }}
              >
                <OutlinedInput
                  autoComplete="off"
                  autoFocus={true}
                  data-testid={testIds.measurementDcr.dataRoomName.nameInput}
                  placeholder="Data clean room name"
                  required={true}
                  sx={{ width: "350px" }}
                  {...field}
                />
              </FormControl>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

DataRoomName.displayName = "DataRoomName";

export default memo(DataRoomName);
