import {
  faCheck as faCheckLight,
  faXmark as faXmarkLight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";

interface UniquenessConstructorProps {
  columnNames: string[];
  columnNameById?: Map<string, string>;
  currentColumns: string[];
  isPublished?: boolean;
  loading?: boolean;
  onAddColumn?: (names: string[]) => void;
  onAddUniqueColumnIds?: () => void;
  onDeleteUniqueColumnIds?: (idsIndexToDelete: number) => void;
  readOnly: boolean;
  uniqueColumnIds?: string[][];
}

const UniquenessConstructor: React.FC<UniquenessConstructorProps> = ({
  columnNames = [],
  columnNameById,
  currentColumns: value,
  isPublished,
  loading,
  onAddColumn,
  onAddUniqueColumnIds,
  onDeleteUniqueColumnIds,
  readOnly,
  uniqueColumnIds,
}) => {
  return (
    <Box>
      {!readOnly ||
      (readOnly && uniqueColumnIds && uniqueColumnIds?.length > 0) ? (
        <Box>Column uniqueness constraints</Box>
      ) : null}
      {!readOnly ? (
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            <Autocomplete
              multiple={true}
              onChange={(event, value, reason) => {
                if (value && onAddColumn) {
                  onAddColumn(value);
                }
              }}
              options={columnNames}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select columns" />
              )}
              value={value.map(
                (uniqueColumnId) => columnNameById?.get(uniqueColumnId) || "?"
              )}
            />
          </Box>
          <IconButton
            disabled={value.length === 0}
            onClick={onAddUniqueColumnIds}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faCheckLight} />
          </IconButton>
        </Box>
      ) : null}
      {uniqueColumnIds?.map((uniqueColumnIdsArray: string[], index: number) => (
        <Box key={index} sx={{ alignItems: "center", display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            <Autocomplete
              multiple={true}
              options={columnNames}
              readOnly={true}
              renderInput={(params) => <TextField {...params} />}
              value={uniqueColumnIdsArray.map(
                (uniqueColumnId) =>
                  columnNameById?.get(uniqueColumnId) ||
                  (isPublished ? uniqueColumnId : "?")
              )}
            />
          </Box>
          {!readOnly ? (
            <IconButton onClick={onDeleteUniqueColumnIds?.bind(null, index)}>
              <FontAwesomeIcon fixedWidth={true} icon={faXmarkLight} />
            </IconButton>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

UniquenessConstructor.displayName = "UniquenessConstructor";

export default UniquenessConstructor;
