import {
  useOrganizationMediaScopeQuery,
  useUpdateOrganizationMediaScopeMutation,
} from "@decentriq/graphql/dist/hooks";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormLabel,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { getEffectiveErrorMessage } from "utils";

interface OrganizationMediaScopeEditorProps {
  organizationId: string;
}

const OrganizationMediaScopeEditor: React.FC<
  OrganizationMediaScopeEditorProps
> = ({ organizationId }) => {
  const [mediaScope, setMediaScope] = useState<string | null>(null);

  const { data: organizationMediaScopeData } = useOrganizationMediaScopeQuery({
    variables: { organizationId },
  });

  const [updateOrganizationMediaScopeMutation] =
    useUpdateOrganizationMediaScopeMutation();

  useEffect(() => {
    const name = organizationMediaScopeData?.organization?.mediaScope;
    if (name) {
      setMediaScope(name);
    }
  }, [organizationMediaScopeData]);

  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });

  const updateOrganizationMediaScope = useCallback(
    async (value: string | null) => {
      if (organizationMediaScopeData?.organization?.mediaScope === value)
        return;
      try {
        await updateOrganizationMediaScopeMutation({
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                mediaScope: () => {
                  return data?.organization?.update?.mediaScope || null;
                },
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            input: {
              id: organizationId,
              mediaScope: { value },
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("Organization name could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [
      organizationMediaScopeData?.organization?.mediaScope,
      updateOrganizationMediaScopeMutation,
      organizationId,
      enqueueSnackbar,
    ]
  );

  const updateOrganizationMediaScopeOnEnter = useCallback(
    (event: any) => {
      const { target, keyCode } = event || {};
      if (keyCode !== 13 || !target.value) return;
      updateOrganizationMediaScope(event.target.value);
    },
    [updateOrganizationMediaScope]
  );

  const clearMediaScope = useCallback(() => {
    updateOrganizationMediaScope(null);
    setMediaScope(null);
  }, [updateOrganizationMediaScope]);

  const onMediaScopeChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      if (value) {
        updateOrganizationMediaScope(value);
      }
    },
    [updateOrganizationMediaScope]
  );

  return (
    <FormControl fullWidth={true}>
      <FormLabel>Media Scope</FormLabel>
      <OutlinedInput
        endAdornment={
          <IconButton onClick={clearMediaScope}>
            <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
          </IconButton>
        }
        name="mediaScope"
        onBlur={onMediaScopeChange}
        onChange={(target) => setMediaScope(target.target.value)}
        onKeyDown={updateOrganizationMediaScopeOnEnter}
        placeholder="Media scope"
        sx={{
          "& .MuiInput-input": { padding: "7px 0" },
          "&:before": { borderBottomStyle: "solid" },
        }}
        value={mediaScope || ""}
      />
    </FormControl>
  );
};

export default OrganizationMediaScopeEditor;
