import { DqCard, DqCardContent } from "@decentriq/components";
import { Alert, Box, Grid } from "@mui/material";
import { memo } from "react";
import { DataLabStatisticsCharts, useDataLabContext } from "features/dataLabs";
import { DataLabStatisticsTile } from "./components";

const DataLabStatistics: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();

  const { statistics: dataLabStatistics, embeddingsDataset } = dataLab || {};
  const {
    original_number_of_unique_users: originalNumberOfUniqueUsers,
    filtered_number_of_unique_users: filteredNumberOfUniqueUsers,
  } = dataLabStatistics || {};

  return (
    <Box mt={1}>
      <Alert severity="info" sx={{ marginBottom: 2 }}>
        To get support with interpreting these results, please contact your
        Decentriq account manager.
      </Alert>
      <Grid columnSpacing={2} container={true}>
        <Grid item={true} md={3} xs={6}>
          <DqCard sx={{ height: "100%" }}>
            <DqCardContent>
              <DataLabStatisticsTile
                title="Unique users"
                tooltipTitle="This is the number of unique users which have been found in at least one of the datasets in this datalab."
                value={originalNumberOfUniqueUsers?.all}
              />
            </DqCardContent>
          </DqCard>
        </Grid>
        <Grid item={true} md={3} xs={6}>
          <DqCard sx={{ height: "100%" }}>
            <DqCardContent>
              <DataLabStatisticsTile
                title="Unique Users with a matching ID"
                tooltipTitle="This is the number of unique user IDs in the Matching table."
                value={originalNumberOfUniqueUsers?.matching}
              />
            </DqCardContent>
          </DqCard>
        </Grid>
        <Grid item={true} md={3} xs={6}>
          <DqCard sx={{ height: "100%" }}>
            <DqCardContent>
              <DataLabStatisticsTile
                title="Addressable unique users"
                tooltipTitle={
                  embeddingsDataset
                    ? "This is the number of unique users in the Embeddings table. As the embeddings will be used for modelling, only these users will be used in the Media DCR. All subsequent statistics are based only on these users."
                    : "This is the number of unique users in the Segments table. As the segments will be used for modelling, only these users will be used in the Media DCR. All subsequent statistics are based only on these users."
                }
                value={filteredNumberOfUniqueUsers?.all}
              />
            </DqCardContent>
          </DqCard>
        </Grid>
        <Grid item={true} md={3} xs={6}>
          <DqCard sx={{ height: "100%" }}>
            <DqCardContent>
              <DataLabStatisticsTile
                title="Addressable unique users with a matching ID"
                tooltipTitle={
                  embeddingsDataset
                    ? "This is the number of unique users who have a matching ID and also appear in the Embeddings table. As the embeddings will be used for modelling, only these users will be used in the Media DCR."
                    : "This is the number of unique users who have a matching ID and also appear in the Segments table. As the segments will be used for modelling, only these users will be used in the Media DCR."
                }
                value={filteredNumberOfUniqueUsers?.matching}
              />
            </DqCardContent>
          </DqCard>
        </Grid>
      </Grid>
      <DataLabStatisticsCharts />
    </Box>
  );
};

DataLabStatistics.displayName = "DataLabStatistics";

export default memo(DataLabStatistics);
