import { testIds } from "@decentriq/utils";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { Checkbox } from "components";
import { RawSupportedFeatures } from "features/MediaInsightsDcr";
import { useCreationWizardConfiguration } from "features/MediaInsightsDcr/contexts";
import { CollaborationTypes } from "models";

const mediaDataRoomFeaturesTooltipMap: Record<
  CollaborationTypes,
  string | null
> = {
  [CollaborationTypes.Insights]:
    "If enabled, advertiser, publisher, agency and observer users will have access to the insights dashboards.",
  [CollaborationTypes.Retargeting]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created remarketing audiences and make them available to the publisher.",
  [CollaborationTypes.Lookalike]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can configure and generate lookalike audiences and make them available to the publisher.",
  [CollaborationTypes.Measurements]:
    "If enabled, advertiser users will be able to request measurement DCRs. This is a separate DCR that does rules-based attribution on brand conversions.",
  [CollaborationTypes.ExclusionTargeting]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created exclusion targeting audiences and make them available to the publisher.",
};

const enabledFeaturesTooltipMap: Record<RawSupportedFeatures, string | null> = {
  [RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD]:
    "If enabled, advertiser and agency users will be able to export audiences they created.",
  [RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS]:
    "If enabled, no absolute numbers will be shown in the insights dashboards and overlap statistics.",
  [RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION]: null,
  [RawSupportedFeatures.ENABLE_EXTENDED_LAL_QUALITY_STATS]: null,
};

const CollaborationTypesEditor: React.FC = () => {
  const {
    allowedCollaborationTypes: allowedFeatures,
    enabledFeatures,
    setEnabledFeatures,
    showAbsoluteAudienceSizes,
    setShowAbsoluteAudienceSizes,
    showAdvertiserAudienceDownload,
    setEnableAdvertiserAudienceDownload,
    enableAdvertiserAudienceDownload,
  } = useCreationWizardConfiguration();
  return (
    <>
      <Typography fontWeight="fontWeightSemiBold" variant="body1">
        4. Collaboration types
      </Typography>
      {allowedFeatures.map((feature) => (
        <Checkbox
          checked={enabledFeatures.includes(feature)}
          data-testid={`${testIds.mediaInsightsDcr.collaborationConfigurationStepRole.allowedFeaturesHelper}${feature}`}
          key={feature}
          label={feature}
          onChange={() => setEnabledFeatures(feature)}
          sx={{ margin: "0 8px 0 0", width: "fit-content" }}
          tooltip={mediaDataRoomFeaturesTooltipMap[feature]}
        />
      ))}
      <Box ml={4}>
        <Checkbox
          checked={showAbsoluteAudienceSizes}
          label="Show absolute audience sizes"
          onChange={(_, checked) => setShowAbsoluteAudienceSizes(checked)}
          sx={{ margin: "0", width: "fit-content" }}
          tooltip={
            enabledFeaturesTooltipMap[
              RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS
            ]
          }
        />
        {showAdvertiserAudienceDownload && (
          <Checkbox
            checked={enableAdvertiserAudienceDownload}
            label="Allow advertiser and agency to export audiences"
            onChange={(_, checked) =>
              setEnableAdvertiserAudienceDownload(checked)
            }
            sx={{ margin: "0", marginLeft: 1, width: "fit-content" }}
            tooltip={
              enabledFeaturesTooltipMap[
                RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
              ]
            }
          />
        )}
      </Box>
    </>
  );
};

CollaborationTypesEditor.displayName = "CollaborationTypesEditor";

export default memo(CollaborationTypesEditor);
