import {
  faClock as faClockRegular,
  faHashtag as faHashtagRegular,
  faTable as faTableRegular,
  faWarning as faWarningRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { DetailsGridValue } from "components";
import { useDataLabContext } from "features/dataLabs/contexts";
import {
  type DataLabDataset,
  matchingIdTypeAndHashingAlgorithmPresentation,
} from "features/dataLabs/models";
import { useCopyToClipboard } from "hooks";

const DataLabDetailsItemContainer = styled(Box)(
  ({ theme: { spacing, palette, shape } }) => ({
    border: `1px solid ${palette.grey[300]}`,
    borderRadius: shape.borderRadius,
    marginBottom: spacing(1),
    padding: spacing(1.5),
  })
);

interface DataLabDetailsProps {
  id: string;
}

const DataLabDetails: React.FC<DataLabDetailsProps> = () => {
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  if (!data || loading) return <></>;

  const dataLab = data!;
  const {
    id,
    updatedAt,
    createdAt,
    matchingIdFormat,
    requireDemographicsDataset,
    requireEmbeddingsDataset,
    matchingIdHashingAlgorithm,
    requireSegmentsDataset,
    numEmbeddings,
    segmentsDataset,
    demographicsDataset,
    embeddingsDataset,
    usersDataset,
  } = dataLab;
  const datasets: DataLabDataset[] = [
    segmentsDataset,
    usersDataset,
    demographicsDataset,
    embeddingsDataset,
  ].filter(Boolean) as DataLabDataset[];
  const dateFormat = "dd.MM.yyy, HH:mm";
  return (
    <Box>
      {!!createdAt && (
        <DataLabDetailsItemContainer>
          <Typography component="div" gutterBottom={true} variant="body2">
            <strong>Created at</strong>
          </Typography>
          <DetailsGridValue
            icon={faClockRegular}
            value={format(new Date(createdAt), dateFormat)}
          />
        </DataLabDetailsItemContainer>
      )}
      {!!updatedAt && (
        <DataLabDetailsItemContainer>
          <Typography component="div" gutterBottom={true} variant="body2">
            <strong>Last modified</strong>
          </Typography>
          <DetailsGridValue
            icon={faClockRegular}
            value={format(new Date(updatedAt), dateFormat)}
          />
        </DataLabDetailsItemContainer>
      )}
      <DataLabDetailsItemContainer>
        <Typography component="div" gutterBottom={true} variant="body2">
          <strong>Datalab ID</strong>
        </Typography>
        <Tooltip
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(id || "");
            enqueueSnackbar(`Datalab ID copied to clipboard`);
          }}
          placement="top"
          style={{ cursor: "pointer" }}
          title="Click to copy Datalab ID"
        >
          <span>
            <DetailsGridValue icon={faHashtagRegular} value={id} />
          </span>
        </Tooltip>
      </DataLabDetailsItemContainer>
      <DataLabDetailsItemContainer>
        <Typography component="div" gutterBottom={true} variant="body2">
          <strong>Data Configuration</strong>
        </Typography>
        <List disablePadding={true}>
          <ListItem>
            <Typography variant="body2">
              <strong>Matching ID:</strong>{" "}
              {matchingIdTypeAndHashingAlgorithmPresentation({
                matchingIdFormat,
                matchingIdHashingAlgorithm,
              })}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              {/* Having Matching dataset is always required while creating a Datalab */}
              <strong>Matching data:</strong> Yes
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <strong>Segments data:</strong>{" "}
              {requireSegmentsDataset ? "Yes" : "No"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <strong>Demographics data:</strong>{" "}
              {requireDemographicsDataset ? "Yes" : "No"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <strong>Embeddings data:</strong>{" "}
              {requireEmbeddingsDataset ? "Yes" : "No"}
            </Typography>
          </ListItem>
          {requireEmbeddingsDataset && (
            <ListItem>
              <Typography variant="body2">
                <strong>Number of embeddings:</strong> {numEmbeddings}
              </Typography>
            </ListItem>
          )}
        </List>
      </DataLabDetailsItemContainer>
      <DataLabDetailsItemContainer>
        <Typography component="div" gutterBottom={true} variant="body2">
          <strong>Datasets</strong>
        </Typography>
        {datasets.length ? (
          <List disablePadding={true}>
            {datasets.map(({ id, name }) => (
              <ListItem key={id} sx={{ gap: 1 }}>
                <ListItemIcon sx={{ color: "inherit", minWidth: "auto" }}>
                  <FontAwesomeIcon fixedWidth={true} icon={faTableRegular} />
                </ListItemIcon>
                <Typography variant="body2">{name}</Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
            <FontAwesomeIcon fixedWidth={true} icon={faWarningRegular} />
            <Typography color="grey.600" fontStyle="italic" variant="body2">
              No datasets provisioned
            </Typography>
          </Box>
        )}
      </DataLabDetailsItemContainer>
    </Box>
  );
};

DataLabDetails.displayName = "DataLabDetails";

export default memo(DataLabDetails);
