import { usePublishedDataRoomLowLevelRepresentationQuery } from "@decentriq/graphql/dist/hooks";
import { faCloudDownload, faUnlink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import saveAs from "file-saver";
import { useCallback, useState } from "react";
import { JsonEditorField, Mainbar } from "components";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

interface DataRoomExpertViewProps {
  dataRoomId: string;
}

const DataRoomExpertView: React.FC<DataRoomExpertViewProps> = ({
  dataRoomId,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const { data, loading, refetch } =
    usePublishedDataRoomLowLevelRepresentationQuery({
      variables: { id: dataRoomId },
    });
  const [isDownloading, setIsDownloading] = useState(false);
  const exportDCRFromEnclave = useCallback(async () => {
    const snackbarKey = enqueueSnackbar(
      "Preparing DCR definition for downloading...",
      {
        persist: true,
      }
    );
    try {
      setIsDownloading(true);
      const result = await refetch();
      const file = new File(
        [JSON.stringify(result, null, 2)],
        `${result.data?.publishedDataRoom?.name}.json`,
        {
          type: "application/octet-stream;charset=utf-8",
        }
      );
      saveAs(file);
    } catch (error) {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          `Data clean room definition could not be exported, or it is not supported in this version.`
        )
      );
    } finally {
      setIsDownloading(false);
      closeSnackbar(snackbarKey);
    }
  }, [enqueueSnackbar, closeSnackbar, refetch]);
  return (
    <>
      <Mainbar />
      <Box
        sx={{ height: "calc(100% - 4px)", minHeight: "4rem", padding: "1rem" }}
      >
        {loading ? (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            <CircularProgress color="inherit" size="1.5rem" thickness={1} />
          </Box>
        ) : data?.publishedDataRoom?.lowLevelRepresentation ? (
          <div
            style={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography sx={{ lingHeight: "24px", mb: 1 }} variant="h6">
              Data clean room definition
            </Typography>
            <Typography variant="body1">
              The expert view renders the JSON that contains the definition of
              the data clean room exactly as it is received and enforced by the
              confidential computing environment. Being provisioned directly by
              the enclave, it cannot be tampered in anyway. Thus, the expert
              view data clean room definition should be considered the source of
              truth for security purposes and it is the ultimate resource to
              know exactly how the input data is being used and who can access
              which result.
            </Typography>
            <Button
              color="inherit"
              disabled={isDownloading}
              onClick={exportDCRFromEnclave}
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={18} thickness={3} />
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faCloudDownload} />
                )
              }
              style={{ marginBottom: "1rem" }}
            >
              {isDownloading
                ? "Downloading"
                : "Download enclave’s expert view definition"}
            </Button>
            <JsonEditorField
              defaultValue={`${JSON.stringify(
                data?.publishedDataRoom?.lowLevelRepresentation,
                null,
                4
              )}`}
              editorOptions={{
                readOnly: true,
                resizable: false,
              }}
            />
          </div>
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            <FontAwesomeIcon fixedWidth={true} icon={faUnlink} size="5x" />
            <Typography>Expert view is not available</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DataRoomExpertView;
