import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  useFormContext,
} from "react-hook-form";

interface DisplayConfigurationDescriptionFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  title: string;
  placeholder: string;
  testId?: string;
}

type DisplayConfigurationDescriptionFieldComponent = <F extends FieldValues>(
  props: DisplayConfigurationDescriptionFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationDescriptionField = memo(
  <F extends FieldValues>({
    fieldName,
    title,
    placeholder,
    testId,
  }: DisplayConfigurationDescriptionFieldProps<F>) => {
    const { control: formControl } = useFormContext<F>();
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[fieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          return (
            <FormControl
              error={!isEmpty(errors[fieldName])}
              fullWidth={true}
              sx={{ mb: 1 }}
            >
              <FormLabel>{title}</FormLabel>
              <OutlinedInput
                minRows={3}
                multiline={true}
                notched={false}
                placeholder={placeholder}
                sx={{
                  pl: 1,
                }}
                {...field}
                data-testid={testId}
              />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);

DisplayConfigurationDescriptionField.displayName =
  "DisplayConfigurationDescriptionField";

export default DisplayConfigurationDescriptionField as DisplayConfigurationDescriptionFieldComponent;
