import { exceptions } from "@decentriq/utils";
import { faCheck, faClock, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataNodeConstructorMode,
  DataNodeUploadDataDialog,
} from "features/dataNodes";
import { DataNodeConstructorParamsWrapper } from "features/dataNodes/components/DataNodeConstructor/DataNodeConstructorParamsWrapper";
import DataNodeDeprovisionButton from "features/dataNodes/components/DataNodeConstructor/DataNodeDeprovisionButton";
import { TableNodeColumnConstructor } from "features/dataNodes/components/DataNodeConstructor/TableNodeColumnConstructor";
import TableNodeColumnConstructorHeader from "features/dataNodes/components/DataNodeConstructor/TableNodeColumnConstructorHeader";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import { useGetValidationReport, useReportError } from "hooks";
import { type DataRoomTableColumn, DataRoomType } from "models";
import { usePublishedMediaInsightsDcr } from "../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import { getAdvertiserAudienceColumns } from "../../models";
import AdvertiserDataReportLabel from "../AdvertiserDataReportLabel/AdvertiserDataReportLabel";
import DataNodeItem from "../DataNodeItem";
import AdvertiserValidationReportDialog from "./AdvertiserValidationReportDialog";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import DeprovisionConfirmationDialog from "./DeprovisionConfirmationDialog";
import useAdvertiserDataNodeActions from "./useAdvertiserDataNodeActions";

interface AdvertiserDataNodeActionsProps {
  dataRoomId: string;
  datasetHash: string | null;
  retrieveDatasets: () => Promise<void>;
  columns: DataRoomTableColumn[];
  setValidationReportOpen: (open: boolean) => void;
}

const AdvertiserDataNodeActions: React.FC<AdvertiserDataNodeActionsProps> = ({
  datasetHash,
  setValidationReportOpen,
  retrieveDatasets,
  columns,
}) => {
  const reportError = useReportError();
  const {
    isAdvertiser,
    dataRoomId,
    driverAttestationHash,
    isDeactivated,
    isDataPartner,
    hasDataPartner,
  } = usePublishedMediaInsightsDcr();
  const {
    handleIngestData,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleDataDelete,
  } = useAdvertiserDataNodeActions({
    datasetHash: datasetHash!,
    driverAttestationHash,
    retrieveDatasets,
  });
  const columnsOrder = useMemo(() => columns.map(({ id }) => id), [columns]);
  const uniqueColumnIds = useMemo(() => [], []);
  const [openConnectDialog, setOpenConnectDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeprovisionDialog, setOpenDeprovisionDialog] = useState(false);
  const toggleOpenConnectDialog = useCallback(
    () => setOpenConnectDialog((state) => !state),
    [setOpenConnectDialog]
  );
  const onDeprovision = useCallback(
    () => handleDataDeprovision("audience_leaf"),
    [handleDataDeprovision]
  );
  const onDelete = useCallback(async () => {
    await handleDataDelete("audience_leaf");
    setOpenDeleteDialog(false);
  }, [handleDataDelete]);
  const isProvisioned = Boolean(datasetHash);
  const userCanInteract = useMemo(
    () => isDataPartner || (isAdvertiser && !hasDataPartner),
    [isAdvertiser, isDataPartner, hasDataPartner]
  );
  const canProvision = useMemo(() => {
    return !isDeactivated && !isProvisioned && userCanInteract;
  }, [userCanInteract, isDeactivated, isProvisioned]);
  const canDeprovision = useMemo(() => {
    return (isProvisioned || !isDeactivated) && userCanInteract;
  }, [isDeactivated, isProvisioned, userCanInteract]);
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      reportError(
        {
          details: error.message,
          errorContext: [
            {
              content: dataRoomId,
              name: "dataRoomId",
            },
          ],
          origin: DataRoomType.LookalikeMedia,
        },
        { silent: true }
      );
    },
    [reportError, dataRoomId]
  );
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: "audience_leaf",
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          "audience_leaf",
          payload.datasetKeychainItem!
        );
      }
    },
    [handleConnectFromKeychain, handleIngestData]
  );
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{ marginRight: "8px" }}
    >
      {canProvision ? (
        <Button
          color="primary"
          onClick={toggleOpenConnectDialog}
          startIcon={<FontAwesomeIcon fixedWidth={true} icon={faUpload} />}
          style={{ marginLeft: "16px", maxWidth: "260px", minWidth: "260px" }}
          variant="contained"
        >
          Provision dataset
        </Button>
      ) : canDeprovision ? (
        <DataNodeDeprovisionButton
          dataType={"table"}
          datasetHash={datasetHash!}
          hasValidationError={true}
          id={"audience_leaf"}
          ingestionDestination={"dataRoom"}
          label={"dataset"}
          onDelete={async () => setOpenDeleteDialog(true)}
          onDeprovision={async () => setOpenDeprovisionDialog(true)}
          openValidationReport={() => setValidationReportOpen(true)}
        />
      ) : (
        <Typography color={grey[400]} fontWeight="bold" variant="body2">
          {isProvisioned ? (
            <>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faCheck}
                style={{ marginRight: "4px" }}
              />
              Data provisioned
            </>
          ) : (
            <>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faClock}
                style={{ marginRight: "4px" }}
              />
              Waiting for data
            </>
          )}
        </Typography>
      )}
      {openConnectDialog ? (
        <DataNodeUploadDataDialog
          columns={columns}
          columnsOrder={columnsOrder}
          id={"audience_leaf"}
          name={"Advertiser audience"}
          onClose={toggleOpenConnectDialog}
          onError={handleError}
          onIngest={onIngest}
          open={openConnectDialog}
          uniqueColumnIds={uniqueColumnIds}
        />
      ) : null}
      <DeprovisionConfirmationDialog
        onCancel={() => setOpenDeprovisionDialog(false)}
        onDeprovision={onDeprovision}
        open={openDeprovisionDialog}
      />
      <DeleteConfirmationDialog
        onCancel={() => setOpenDeleteDialog(false)}
        onDelete={onDelete}
        open={openDeleteDialog}
      />
    </Box>
  );
};

interface AdvertiserDataNodeProps {
  datasetHash: string | null;
  retrieveDatasets: () => Promise<void>;
}

const AdvertiserDataNode: React.FC<AdvertiserDataNodeProps> = ({
  datasetHash,
  retrieveDatasets,
}) => {
  const {
    dataRoomId,
    matchingIdFormat,
    hashMatchingIdWith,
    isAdvertiser,
    isDataPartner,
    hasDataPartner,
    driverAttestationHash,
  } = usePublishedMediaInsightsDcr();
  const detailsVisible = hasDataPartner ? isDataPartner : isAdvertiser;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [validationReportOpen, setValidationReportOpen] = useState(false);
  const validationReport = useGetValidationReport({
    dataRoomId,
    // Hardcoded datanodeId in LAL DCR
    datanodeId: "audiences",
    datasetHash,
    driverAttestationHash,
    enabled: validationReportOpen,
  });
  const toggleCollapse = useCallback(
    () => setIsCollapsed((state) => !state),
    [setIsCollapsed]
  );
  const columns = useMemo<DataRoomTableColumn[]>(
    () => getAdvertiserAudienceColumns(matchingIdFormat, hashMatchingIdWith),
    [matchingIdFormat, hashMatchingIdWith]
  );
  useEffect(() => {
    if (validationReport.error) {
      enqueueSnackbar("Failed to retrieve validation report", {
        context: `${validationReport.error}`,
        persist: true,
        variant: "error",
      });
    }
  }, [validationReport.error]);

  return (
    <>
      <DataNodeItem
        actions={
          <AdvertiserDataNodeActions
            columns={columns}
            dataRoomId={dataRoomId}
            datasetHash={datasetHash}
            retrieveDatasets={retrieveDatasets}
            setValidationReportOpen={setValidationReportOpen}
          />
        }
        content={
          detailsVisible && (
            <DataNodeConstructorParamsWrapper
              mode={DataNodeConstructorMode.ACTION}
            >
              <Box sx={{ padding: (theme) => theme.spacing(1, 0) }}>
                <TableNodeColumnConstructorHeader
                  isListEmpty={false}
                  readOnly={true}
                />
                <TableNodeColumnConstructor
                  columns={columns}
                  columnsOrder={columns.map(({ id }) => id)}
                  isLoading={false}
                  tableNodeId={"audience_leaf"}
                />
              </Box>
            </DataNodeConstructorParamsWrapper>
          )
        }
        description={
          datasetHash && detailsVisible ? <AdvertiserDataReportLabel /> : null
        }
        isCollapsed={isCollapsed || !detailsVisible}
        title="Advertiser audience"
        toggleCollapse={detailsVisible ? toggleCollapse : undefined}
      />
      <AdvertiserValidationReportDialog
        data={validationReport.data}
        loading={validationReport.isLoading}
        onClose={() => setValidationReportOpen(false)}
        open={validationReportOpen}
      />
    </>
  );
};

AdvertiserDataNode.displayName = "AdvertiserDataNode";

export default AdvertiserDataNode;
