import {
  useEditUserRoleMutation,
  useUserDetailsQuery,
} from "@decentriq/graphql/dist/hooks";
import { UserRole } from "@decentriq/graphql/dist/types";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { useIsDecentriqOrganization, useUserRole } from "hooks";
import { userRolePresentation } from "models";

type UserRoleFieldProps = {
  userId: string;
};

const UserRoleField: React.FC<UserRoleFieldProps> = ({ userId }) => {
  const [editUserRoleMutation] = useEditUserRoleMutation();
  const { data: { user } = {}, loading } = useUserDetailsQuery({
    variables: { userId },
  });

  const isDecentriqOrganization = useIsDecentriqOrganization(
    user?.organization?.id!
  );
  const { isSuperAdmin, isSuperAdminReadOnly } = useUserRole();

  const rolesOptions = [
    UserRole.NormalUser,
    UserRole.OrganizationAdmin,
    ...(isDecentriqOrganization && isSuperAdmin
      ? [UserRole.SuperAdmin, UserRole.SuperAdminReadOnly]
      : []),
  ];

  // User with lower role than SUPER_ADMIN cannot change role for super admin
  const isUserRoleSelectDisabled =
    user?.isDemoUser ||
    isSuperAdminReadOnly ||
    (user?.userRole === UserRole.SuperAdmin && !isSuperAdmin);

  const editUserRole = useCallback(
    async ({ target }: SelectChangeEvent<UserRole>) => {
      if (user?.userRole === target?.value) {
        return;
      }
      await editUserRoleMutation({
        optimisticResponse: {
          user: {
            setRole: {
              __typename: "UpdateUserPayload",
              query: {
                __typename: "Query",
                user: {
                  __typename: "User",
                  id: userId,
                  userRole: target?.value as UserRole,
                },
              },
              record: {
                __typename: "User",
                id: userId,
                userRole: target?.value as UserRole,
              },
            },
          },
        },
        variables: {
          input: {
            userId,
            userRole: target?.value as UserRole,
          },
          userId,
        },
      });
    },
    [editUserRoleMutation, userId, user?.userRole]
  );

  return (
    <FormControl
      fullWidth={true}
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        marginBottom: 1,
        width: "40%",
      }}
    >
      <Typography sx={{ color: "inherit" }}>Role: </Typography>
      {loading ? (
        <CircularProgress size={16} sx={{ marginLeft: "6px" }} />
      ) : (
        <Select
          disabled={isUserRoleSelectDisabled}
          displayEmpty={true}
          onChange={editUserRole}
          renderValue={(value) => {
            return userRolePresentation.get(value || user?.userRole);
          }}
          sx={{ marginLeft: 1 }}
          value={user?.userRole}
        >
          {(rolesOptions as UserRole[]).map((role) => (
            <MenuItem key={role} value={role}>
              {userRolePresentation.get(role)}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

UserRoleField.displayName = "UserRoleField";

export default memo(UserRoleField);
