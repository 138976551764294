import { FormControlLabel, Switch } from "@mui/material";
import { useDataRoom } from "contexts";
import usePublishedDataRoomTestMode from "../hooks/usePublishedDataRoomTestMode/usePublishedDataRoomTestMode";

const TestModeSwitch: React.FC = () => {
  const { allowTestMode } = useDataRoom();
  const [checked, handleChange] = usePublishedDataRoomTestMode();
  if (!allowTestMode) {
    return <></>;
  }
  return (
    <FormControlLabel
      control={
        <Switch checked={checked} color="secondary" onChange={handleChange} />
      }
      label="Test mode"
      sx={{
        margin: 0,
        marginRight: "1rem",
      }}
    />
  );
};

export default TestModeSwitch;
