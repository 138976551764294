import { testIds } from "@decentriq/utils";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { useSafeState } from "ahooks";
import omit from "lodash/omit";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Checkbox } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import {
  matchingIdTypeToGqlValues,
  type RawMatchingID,
} from "models/dataRoom/matchingId";
import { getEffectiveErrorMessage } from "utils";
import { useDataLabsContext } from "../../contexts";

interface DataLabsCreateDialogProps {
  open: boolean;
  onCancel: (id?: string) => void;
}

const validationSchema = yup.object({
  matchingDataMatchingIdType: yup
    .string()
    .trim()
    .required("Matching ID type is required")
    .defined(),
  name: yup.string().trim().required("Name is required").defined(),
  numEmbeddings: yup
    .number()
    .min(10, "Embeddings per user can't be less than 10")
    .when("useUserEmbeddingData", (useUserEmbeddingData, schema) =>
      useUserEmbeddingData
        ? schema.min(1, "Please specify embeddings per user").required()
        : schema.optional()
    )
    .defined(),
  useDemographicsData: yup.bool().defined(),
  useMatchingData: yup.bool().required("Matching data is required").defined(),
  useSegmentsData: yup.bool().defined(),
  useUserEmbeddingData: yup.bool().defined(),
});

type DataLabFormValues = yup.InferType<typeof validationSchema>;

const formValues: DataLabFormValues = {
  matchingDataMatchingIdType: "",
  name: "",
  numEmbeddings: 10,
  useDemographicsData: false,
  useMatchingData: true,
  useSegmentsData: false,
  useUserEmbeddingData: false,
};

const formValuesForCheckboxes = omit(
  formValues,
  "name",
  "numEmbedings",
  "matchingDataMatchingIdType"
);

export const matchingIdTypes: { name: string; value: string }[] = [
  { name: "String", value: "string" },
  {
    name: "Email",
    value: "email",
  },
  { name: "Hashed email", value: "hashed_email" },
  { name: "Phone number", value: "phone_number" },
  { name: "Hashed phone number", value: "hashed_phone_number" },
];

const DataLabCreateDialog: React.FC<DataLabsCreateDialogProps> = ({
  open,
  onCancel,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DATA_LAB,
  });
  const navigate = useNavigate();
  const { createDataLab } = useDataLabsContext();
  const [loading, setLoading] = useSafeState(false);
  const [name, setName] = useState(formValues.name);
  const [matchingDataMatchingIdType, setMatchingDataMatchingIdType] =
    useState<string>(formValues.matchingDataMatchingIdType);
  const [numEmbeddings, setNumEmbeddings] = useState(formValues.numEmbeddings);
  const [checkboxValues, setCheckboxValues] = useState(formValuesForCheckboxes);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const valid = validationErrors === undefined;
  const validate = useCallback(
    async (values: DataLabFormValues): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  useEffect(() => {
    if (!open) {
      setNumEmbeddings(formValues.numEmbeddings);
      setName(formValues.name);
      setMatchingDataMatchingIdType(formValues.matchingDataMatchingIdType);
      setCheckboxValues(formValuesForCheckboxes);
    }
  }, [
    open,
    setNumEmbeddings,
    setName,
    setCheckboxValues,
    setMatchingDataMatchingIdType,
  ]);
  const handleSubmit = useCallback(
    async (formValues: DataLabFormValues) => {
      try {
        setLoading(true);
        const isValid = await validate(formValues);
        if (isValid) {
          const response = await createDataLab({
            matchingIdFormat:
              matchingIdTypeToGqlValues[
                formValues.matchingDataMatchingIdType as RawMatchingID
              ][0],
            matchingIdHashingAlgorithm:
              matchingIdTypeToGqlValues[
                formValues.matchingDataMatchingIdType as RawMatchingID
              ][1],
            name: formValues.name,
            numEmbeddings: formValues.numEmbeddings,
            requireDemographicsDataset: formValues.useDemographicsData,
            requireEmbeddingsDataset: formValues.useUserEmbeddingData,
            requireSegmentsDataset: formValues.useSegmentsData,
          });
          const id = response?.data?.dataLab.create.record.id;
          onCancel();
          navigate(`/publisherportal/datalabs/${id}`);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("Unable to create datalab", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [setLoading, createDataLab, enqueueSnackbar, validate, onCancel, navigate]
  );
  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValidationErrors(undefined);
      setName(event.target.value);
    },
    [setValidationErrors, setName]
  );
  const handleCheckboxChange = useCallback(
    ({ target: { name, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setValidationErrors(undefined);
      setCheckboxValues((prev) => ({
        ...prev,
        [name]: checked,
      }));
      if (name === "useUserEmbeddingData" && name) {
        setNumEmbeddings(formValues.numEmbeddings);
      }
    },
    [setCheckboxValues, setValidationErrors, setNumEmbeddings]
  );
  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      setValidationErrors(undefined);
      setMatchingDataMatchingIdType(event.target.value);
    },
    [setValidationErrors, setMatchingDataMatchingIdType]
  );
  const handleNumericChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValidationErrors(undefined);
      setNumEmbeddings(parseInt(event.target.value));
    },
    [setValidationErrors, setNumEmbeddings]
  );
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} scroll="paper">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit({
            name,
            ...checkboxValues,
            matchingDataMatchingIdType,
            numEmbeddings,
          });
        }}
      >
        <DialogTitle>Data insights preparation lab</DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <FormControl fullWidth={true}>
                <OutlinedInput
                  autoComplete="off"
                  autoFocus={true}
                  data-testid={testIds.dataLabs.dataLabCreateDialog.nameInput}
                  onChange={handleNameChange}
                  placeholder="Datalab name (required)"
                  value={name}
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <Checkbox
                checked={checkboxValues.useMatchingData}
                data-testid={
                  testIds.dataLabs.dataLabCreateDialog.matchingDataCheckbox
                }
                disabled={true}
                label={"Use matching data"}
                name="useMatchingData"
                onChange={handleCheckboxChange}
                sx={{ margin: 0, width: "fit-content" }}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Checkbox
                checked={checkboxValues.useDemographicsData}
                data-testid={
                  testIds.dataLabs.dataLabCreateDialog.demographicDataCheckbox
                }
                label={"Use demographics data"}
                name="useDemographicsData"
                onChange={handleCheckboxChange}
                sx={{ margin: 0, width: "fit-content" }}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Checkbox
                checked={checkboxValues.useSegmentsData}
                data-testid={
                  testIds.dataLabs.dataLabCreateDialog.segmentsDataCheckbox
                }
                label={"Use segments data"}
                name="useSegmentsData"
                onChange={handleCheckboxChange}
                sx={{ margin: 0, width: "fit-content" }}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Checkbox
                checked={checkboxValues.useUserEmbeddingData}
                data-testid={
                  testIds.dataLabs.dataLabCreateDialog.userEmbeddingDataCheckbox
                }
                label={"Use user embedding data"}
                name="useUserEmbeddingData"
                onChange={handleCheckboxChange}
                sx={{ margin: 0, width: "fit-content" }}
              />
            </Grid>
            <Grid item={true} xs={checkboxValues.useUserEmbeddingData ? 6 : 12}>
              <FormControl fullWidth={true}>
                <FormLabel>Matching ID type</FormLabel>
                <Select
                  data-testid={
                    testIds.dataLabs.dataLabCreateDialog.matchingIdSelector
                  }
                  displayEmpty={true}
                  onChange={handleSelectChange}
                  renderValue={(value) => {
                    const option = matchingIdTypes.find(
                      (o) => o.value === value
                    );
                    if (!option) {
                      return "None";
                    }
                    return option.name;
                  }}
                  sx={{ minWidth: 144 }}
                  value={matchingDataMatchingIdType}
                >
                  {matchingIdTypes.map(({ value, name: label }, index) => (
                    <MenuItem
                      data-testid={`${testIds.dataLabs.dataLabCreateDialog.matchingIdHelper}${value}`}
                      key={`${value}-${index}`}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {checkboxValues.useUserEmbeddingData && (
              <Grid item={true} xs={6}>
                <FormControl fullWidth={true}>
                  <FormLabel>Embeddings per User</FormLabel>
                  <OutlinedInput
                    data-testid={
                      testIds.dataLabs.dataLabCreateDialog.numEmbeddingsInput
                    }
                    inputProps={{ min: formValues.numEmbeddings }}
                    onChange={handleNumericChange}
                    type="number"
                    value={numEmbeddings}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          {!valid ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
                {validationErrors?.map((validationError, index) => (
                  <li key={index}>{validationError}</li>
                ))}
              </ul>
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => onCancel()} variant="text">
            Cancel
          </Button>
          <Tooltip
            placement="top"
            title={
              !valid ? "Make sure that all mandatory fields are filled." : ""
            }
          >
            <span>
              <Button
                color="primary"
                data-testid={testIds.dataLabs.dataLabCreateDialog.submitButton}
                disabled={loading}
                type="submit"
                variant="contained"
              >
                Create lab
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DataLabCreateDialog;
