import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import { memo } from "react";
import { usePublishedDataRoom } from "contexts";

const RequestCreatorPlaceholder: React.FC = () => {
  const { switchToDev } = usePublishedDataRoom();
  return (
    <div>
      <Typography>To create new requests:</Typography>
      <ul>
        <li>Go to the DEVELOPMENT tab and create a new computation</li>
        <li>Turn the computation into a request</li>
      </ul>
      <Button
        color="inherit"
        endIcon={<FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />}
        onClick={switchToDev}
      >
        Go to DEVELOPMENT tab
      </Button>
    </div>
  );
};

RequestCreatorPlaceholder.displayName = "RequestCreatorPlaceholder";

export default memo(RequestCreatorPlaceholder);
