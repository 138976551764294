import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface AttributionItemProps {
  options: { label: string; value: string | number }[];
  attributionLabel: string;
  fieldName: string;
  customOptions?: JSX.Element[];
}

const AttributionItem: React.FC<AttributionItemProps> = ({
  options,
  attributionLabel,
  fieldName,
  customOptions,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => {
        return (
          <FormControl sx={{ mb: 2, width: "250px" }}>
            <InputLabel>{attributionLabel}</InputLabel>
            <Select displayEmpty={true} label={attributionLabel} {...field}>
              {customOptions?.length
                ? customOptions
                : options.map(({ label, value }) => {
                    return (
                      <MenuItem key={`${value}-${label}`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};

export default memo(AttributionItem);
