import { InfoTooltip } from "@decentriq/components";
import { Box, styled, Typography } from "@mui/material";
import { memo } from "react";

const DataLabStatisticsTileBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

type DataLabStatisticsTileProps = {
  title: string;
  tooltipTitle: string;
  value: number;
};

const DataLabStatisticsTile: React.FC<DataLabStatisticsTileProps> = ({
  title,
  tooltipTitle,
  value,
}) => {
  const formatter = Intl.NumberFormat("en-US", { notation: "compact" });
  return (
    <DataLabStatisticsTileBox>
      <Typography color="primary.dark" fontWeight={700} mb={0.5} variant="h4">
        {formatter.format(value)}
      </Typography>
      <Typography textAlign="center" variant="body2">
        {title}
        <InfoTooltip tooltip={tooltipTitle} />
      </Typography>
    </DataLabStatisticsTileBox>
  );
};

DataLabStatisticsTile.displayName = "DataLabStatisticsTile";

export default memo(DataLabStatisticsTile);
