import { useCreateOrganizationMutation } from "@decentriq/graphql/dist/hooks";
import {
  OrganizationFragment,
  OrganizationState,
} from "@decentriq/graphql/dist/types";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminDialog } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface OrganizationsAddDialogProps {
  open: boolean;
  onCancel: () => void;
}

const OrganizationsAddDialog: React.FC<OrganizationsAddDialogProps> = ({
  open,
  onCancel = () => {},
}) => {
  const [organizationName, setOrganizationName] = useState<string>("");
  const [organizationLicense, setOrganizationLicense] = useState<
    OrganizationState | undefined
  >();

  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const navigate = useNavigate();

  const [createOrganization, { loading }] = useCreateOrganizationMutation({
    onCompleted: ({ organization = {} }) => {
      enqueueSnackbar(`Organization has been successfully created.`);
      onCancel();
      navigate(`/admin/organizations/${organization?.create?.id}`);
    },
    onError: (error) =>
      enqueueSnackbar(`Organization could not be created.`, {
        context: error?.message,
        persist: true,
        variant: "error",
      }),
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          organizations: (existing = {}) => {
            const organizationRef = cache.writeFragment({
              data: data?.organization?.create,
              fragment: OrganizationFragment,
            });
            return {
              ...existing,
              nodes: [organizationRef, ...(existing?.nodes || [])],
            };
          },
        },
      });
    },
    variables: {
      input: {
        name: organizationName,
        state: organizationLicense!,
      },
    },
  });

  useEffect(() => {
    if (!open) {
      setOrganizationName("");
      setOrganizationLicense(undefined);
    }
  }, [open, setOrganizationName, setOrganizationLicense]);

  return (
    <AdminDialog
      disabled={!organizationName || !organizationLicense}
      loading={loading}
      onClose={onCancel}
      onConfirm={createOrganization}
      open={open}
      title="Create organization"
    >
      <FormControl fullWidth={true}>
        <OutlinedInput
          name="name"
          onChange={({ target }) => setOrganizationName(target?.value || "")}
          placeholder="Name"
          value={organizationName}
        />
      </FormControl>
      <FormControl fullWidth={true}>
        <Select
          displayEmpty={true}
          onChange={({ target }) =>
            setOrganizationLicense(target?.value as OrganizationState)
          }
          renderValue={(value: OrganizationState) =>
            capitalize(value) || "License status"
          }
          sx={{ marginTop: 2 }}
        >
          {Object.values(OrganizationState).map((state) => (
            <MenuItem key={state} value={state}>
              {capitalize(state)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </AdminDialog>
  );
};

export default OrganizationsAddDialog;
