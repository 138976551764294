import { useUpdateS3UploadDependencyMutation } from "@decentriq/graphql/dist/hooks";
import { FormControl, FormLabel, Grid, MenuItem, Select } from "@mui/material";
import { memo, useCallback } from "react";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";
import { ComputeNodeTypeNames } from "models";
import useS3ObjectUploadDependency from "./useS3ObjectUpload";

interface ObjectUploadFormProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const ObjectUploadForm: React.FC<ObjectUploadFormProps> = memo(
  ({ computeNodeId, readOnly = false }): JSX.Element => {
    const { uploadDependency } = useS3ObjectUploadDependency(computeNodeId);
    const s3UploadDependency = uploadDependency?.id;
    const objectUploadDependencyTypenames = Object.values(
      ComputeNodeTypeNames
    ).filter(
      (typename) =>
        typename !== ComputeNodeTypeNames.DraftS3SinkNode &&
        typename !== ComputeNodeTypeNames.PublishedS3SinkNode
    ) as string[];
    const { nodes } = useNodes();
    const computeNodes =
      nodes
        .filter(({ __typename }) =>
          objectUploadDependencyTypenames.includes(__typename!)
        )
        .map(({ id, name }) => ({
          id,
          label: name,
        })) || [];
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [updateS3UploadDependencyMutation] =
      useUpdateS3UploadDependencyMutation();
    const updateS3UploadDependency = useCallback(
      async (dependencyId: string) => {
        try {
          return updateS3UploadDependencyMutation({
            variables: {
              computeNodeId,
              dependencyId: { draft: dependencyId },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "S3 settings could not be updated."
            )
          );
          throw error;
        }
      },
      [enqueueSnackbar, computeNodeId, updateS3UploadDependencyMutation]
    );
    return (
      <Grid alignItems="center" container={true} spacing={3}>
        <Grid item={true} md={4} xs={4}>
          <FormControl fullWidth={true}>
            <FormLabel>Source computation</FormLabel>
            <Select
              defaultValue={s3UploadDependency}
              disabled={readOnly}
              displayEmpty={true}
              onChange={({ target }) => {
                updateS3UploadDependency(target.value);
              }}
              renderValue={(value) => {
                return (
                  computeNodes.find(({ id }) => id === value)?.label ||
                  "Choose source computation..."
                );
              }}
            >
              {computeNodes.map(({ id, label }: any) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
);

ObjectUploadForm.displayName = "ObjectUploadForm";

export default ObjectUploadForm;
