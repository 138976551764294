import { testIds } from "@decentriq/utils";
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material";
import { useDataLabContext } from "features/dataLabs";

const DataLabValidationButton: React.FC = () => {
  const { palette } = useTheme();
  const {
    computeStatistics,
    statisticsLoading,
    dataLab: { data },
  } = useDataLabContext();
  const {
    usersDataset,
    embeddingsDataset,
    requireEmbeddingsDataset,
    demographicsDataset,
    requireDemographicsDataset,
    requireSegmentsDataset,
    segmentsDataset,
  } = data!;
  const enabled =
    Boolean(usersDataset) &&
    (Boolean(embeddingsDataset) || !requireEmbeddingsDataset) &&
    (Boolean(demographicsDataset) || !requireDemographicsDataset) &&
    (Boolean(segmentsDataset) || !requireSegmentsDataset);
  return (
    <LoadingButton
      data-testid={testIds.dataLabs.dataLabValidation.dataLabValidationButton}
      disabled={!enabled}
      loading={statisticsLoading}
      onClick={computeStatistics}
      startIcon={
        <FontAwesomeIcon
          color={palette.primary.main}
          fixedWidth={true}
          icon={faChartSimple}
        />
      }
    >
      Compute statistics
    </LoadingButton>
  );
};

export default DataLabValidationButton;
