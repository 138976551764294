import {
  DqSidePanel,
  DqSidePanelContent,
  DqSidePanelTitle,
} from "@decentriq/components";
import { memo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";
import {
  AudienceQualityStatisticsItemBox,
  PositiveCurveScoreBar,
  PositiveCurveScoreChart,
} from "../AudienceQualityStatistics";

const AudienceQualityStatisticsDrawer: React.FC = () => {
  const {
    isAdvertiserAudienceQualityDrawerOpen,
    closeAdvertiserAudienceQualityDrawer,
  } = useAdvertiserAudienceGenerator();

  return (
    <DqSidePanel
      PaperProps={{ sx: { width: 650 } }}
      anchor="right"
      hideBackdrop={false}
      onClose={closeAdvertiserAudienceQualityDrawer}
      open={isAdvertiserAudienceQualityDrawerOpen}
    >
      <DqSidePanelTitle
        onClose={closeAdvertiserAudienceQualityDrawer}
        separated={true}
      >
        Recall
      </DqSidePanelTitle>
      <DqSidePanelContent>
        <AudienceQualityStatisticsItemBox height="fit-content">
          <PositiveCurveScoreBar withChartButton={false} />
        </AudienceQualityStatisticsItemBox>
        <PositiveCurveScoreChart />
      </DqSidePanelContent>
    </DqSidePanel>
  );
};

AudienceQualityStatisticsDrawer.displayName = "AudienceQualityStatisticsDrawer";

export default memo(AudienceQualityStatisticsDrawer);
