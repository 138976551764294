import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const GoogleCloudStorageFormBucketField = () => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="configuration.bucketName"
      render={({ field, formState }) => {
        const { errors } = formState;
        const fieldError = errors?.configuration?.bucketName;
        return (
          <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
            <InputLabel>Bucket name</InputLabel>
            <OutlinedInput
              label="Bucket name"
              placeholder="Example: my-bucket-name"
              {...field}
            />
            <FormHelperText>{fieldError?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

GoogleCloudStorageFormBucketField.displayName =
  "GoogleCloudStorageFormBucketField";

export default memo(GoogleCloudStorageFormBucketField);
