import { faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { get } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type Merge,
  useFieldArray,
  useFormContext,
} from "react-hook-form";

const SegmentOwnersField: React.FC = () => {
  const { control } = useFormContext();
  const {
    fields: segmentOwnersFields,
    append: appendSegmentOwner,
    remove: removeSegmentOwner,
  } = useFieldArray({
    control,
    name: "segmentOwners",
  });

  return (
    <Stack spacing={1}>
      {segmentOwnersFields.length ? (
        <FormLabel>Segment Owners</FormLabel>
      ) : null}
      {segmentOwnersFields.map((arrayFieldId, index) => {
        return (
          <Box display="flex" key={arrayFieldId.id}>
            <Controller
              control={control}
              name={`segmentOwners.${index}.id`}
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = get(errors?.segmentOwners, `${index}.id`) as
                  | Merge<FieldError, (FieldError | undefined)[]>
                  | undefined;
                return (
                  <FormControl error={!!fieldError} fullWidth={true}>
                    <InputLabel>{`Segment Owner ${index + 1}`}</InputLabel>
                    <OutlinedInput
                      label={`Segment Owner ${index + 1}`}
                      placeholder={`Example: KLAB00${index + 1}`}
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message || ""}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            {segmentOwnersFields.length > 1 && (
              <IconButton
                color="error"
                onClick={() => removeSegmentOwner(index)}
                sx={{ ml: 1, width: "60px" }}
              >
                <FontAwesomeIcon fixedWidth={true} icon={faTrash} />
              </IconButton>
            )}
          </Box>
        );
      })}
      <Button
        onClick={() => appendSegmentOwner({ id: "" })}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        sx={{ width: "fit-content" }}
        variant="text"
      >
        Add Segment Owner
      </Button>
    </Stack>
  );
};

SegmentOwnersField.displayName = "SegmentOwnersField";

export default memo(SegmentOwnersField);
