import {
  Alert,
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { AddParticipantField, ParticipantForm } from "components";
import { usePermissionsVars } from "contexts";
import useDataRoomParticipants from "./useDataRoomParticipants";

interface ParticipantsConstructorProps {
  id: string;
}

const ParticipantsListHeader = styled(Grid)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: "inherit",
  },
  "& > .MuiGrid-item": {
    padding: theme.spacing(0, 1.25),
  },
  borderBottom: `1px solid lightgrey`,
  display: "flex",
  padding: theme.spacing(1, 0),
}));

const ParticipantsConstructor: React.FC<ParticipantsConstructorProps> = ({
  id,
}) => {
  const {
    participants = [],
    isLoading = false,
    error,
  } = useDataRoomParticipants();
  const { canEditPermissions } = usePermissionsVars();
  if (isLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress color="inherit" size="1.5rem" thickness={1} />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error">
        Data clean room participants could not be retrieved. Please try again by
        refreshing the page.
      </Alert>
    );
  }
  return (
    <Box
      sx={{
        background: "white",
        border: "1px solid #bbb",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "12px",
      }}
    >
      <ParticipantsListHeader alignItems="flex-end" container={true}>
        <Grid alignItems="center" item={true} xs={5}>
          <FormLabel>Email</FormLabel>
        </Grid>
        <Grid alignItems="center" item={true} xs={3}>
          <FormLabel>Data owner of</FormLabel>
        </Grid>
        <Grid alignItems="center" item={true} xs={3}>
          <FormLabel>Analyst of</FormLabel>
        </Grid>
        {canEditPermissions ? (
          <Grid alignItems="center" item={true} xs="auto">
            <FormLabel sx={{ width: "32px" }} />
          </Grid>
        ) : null}
      </ParticipantsListHeader>
      {participants.length > 0
        ? participants.map((participant) => (
            <ParticipantForm
              key={participant?.userEmail}
              participant={participant}
            />
          ))
        : null}
      {!participants.length ? (
        <Typography variant="body1">
          This data clean room doesn't have any participants yet.
        </Typography>
      ) : undefined}
      {canEditPermissions && (
        <AddParticipantField
          emails={participants.map((p) => p.userEmail)}
          idForDataRoom={id}
        />
      )}
      {canEditPermissions && (
        <Typography
          sx={({ palette }) => ({
            color: palette.text.secondary,
            marginTop: "auto",
          })}
        >
          Please double-check that all participants have a Decentriq Platform
          account.
          <br />
          Invite external participants via the Admin interface.
        </Typography>
      )}
    </Box>
  );
};

ParticipantsConstructor.displayName = "ParticipantsConstructor";

export default ParticipantsConstructor;
