import { InfoTooltip } from "@decentriq/components";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  useFormContext,
} from "react-hook-form";
import { type OrganizationUser } from "../../hooks/useOrganizationUsers/useOrganizationUsers";

interface DisplayConfigurationCollaborativeUsersFieldProps<
  F extends FieldValues,
> {
  fieldName: Path<F>;
  organizationUsers: OrganizationUser[];
  testId?: string;
  optionTestId?: string;
}

type DisplayConfigurationCollaborativeUsersFieldComponent = <
  F extends FieldValues,
>(
  props: DisplayConfigurationCollaborativeUsersFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationCollaborativeUsersField = memo(
  <F extends FieldValues>({
    fieldName,
    organizationUsers,
    testId,
    optionTestId,
  }: DisplayConfigurationCollaborativeUsersFieldProps<F>) => {
    const { control: formControl, watch } = useFormContext<F>();
    const selectedPublisherParticipants = watch(fieldName);
    const handleRenderValue = useCallback((selectedEmails: string[]) => {
      if (selectedEmails.length === 1) {
        return selectedEmails[0];
      }
      return (
        <Tooltip placement="top" title={selectedEmails.join(", ")}>
          <span>{`${selectedEmails.length} participants`}</span>
        </Tooltip>
      );
    }, []);
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[fieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          const withError = !isEmpty(fieldError);
          return (
            <FormControl
              error={withError}
              fullWidth={true}
              sx={{ marginBottom: 1, width: "100%" }}
            >
              <FormLabel>
                Contact request email
                <InfoTooltip tooltip="Contact requests by advertisers will be sent to these email addresses." />
              </FormLabel>
              <Select
                MenuProps={{
                  sx: { maxHeight: "370px" },
                }}
                displayEmpty={true}
                multiple={true}
                renderValue={handleRenderValue}
                {...field}
                data-testid={testId}
              >
                {organizationUsers.map(({ id, email }) => (
                  <MenuItem
                    key={id}
                    sx={({ spacing }) => ({ padding: spacing(1, 2) })}
                    value={email}
                    {...(optionTestId
                      ? {
                          "data-testid": `${optionTestId}${email.toLowerCase()}`,
                        }
                      : {})}
                  >
                    <Checkbox
                      checked={selectedPublisherParticipants?.includes(email)}
                    />
                    <ListItemText primary={email} />
                  </MenuItem>
                ))}
              </Select>
              {withError && (
                <FormHelperText sx={{ margin: "4px 4px 0" }}>
                  {fieldError?.message || ""}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    );
  }
);

DisplayConfigurationCollaborativeUsersField.displayName =
  "DisplayConfigurationCollaborativeUsersField";

export default DisplayConfigurationCollaborativeUsersField as DisplayConfigurationCollaborativeUsersFieldComponent;
