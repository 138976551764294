import { faCheckCircle, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { memo } from "react";
import { Chip } from "components";

interface DataLabStatusLabelProps {
  isValidated: boolean;
}

const DataLabStatusLabel: React.FC<DataLabStatusLabelProps> = ({
  isValidated,
}) => {
  return (
    <Box sx={{ alignItems: "center", display: "flex", mt: "2px" }}>
      <Chip
        color={isValidated ? "success" : "warning"}
        icon={
          <FontAwesomeIcon icon={isValidated ? faCheckCircle : faWarning} />
        }
        label={isValidated ? "Validated" : "Not validated"}
      />
    </Box>
  );
};

DataLabStatusLabel.displayName = "DataLabStatusLabel";

export default memo(DataLabStatusLabel);
