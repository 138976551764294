// @ts-nocheck
import { testIds } from "@decentriq/utils";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV as faEllipsisVRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  type SxProps,
  type Theme,
  Tooltip,
} from "@mui/material";
import { useBoolean, useHover, useKeyPress } from "ahooks";
import {
  cloneElement,
  createContext,
  type Element,
  forwardRef,
  Fragment,
  memo,
  useCallback,
  useContext,
  useRef,
} from "react";
import { isElement, isValidElementType } from "react-is";
import { makeStyles } from "tss-react/mui";
import { usePopupState } from "hooks";

const ActionsContext = createContext({
  closePopover: () => {},
  openPopover: () => {},
});

const useActions = () => useContext(ActionsContext);

const figureOutTooltipTitle = (name, tooltipTitle) =>
  typeof name === "string" && typeof tooltipTitle === "string"
    ? name.replace(/\.\.\.$/, "") === tooltipTitle.replace(/\.\.\.$/, "")
      ? ""
      : tooltipTitle || ""
    : tooltipTitle || "";

const useButtonStyles = makeStyles()((
  theme: Theme,
  { level, isRed, inline }
) => {
  return {
    outlinedSizeMedium: {
      paddingBottom: theme.spacing(0.375),
      paddingTop: theme.spacing(0.375),
    },
    root: {
      borderRadius: theme.shape.borderRadius,
      minWidth: 0,
      padding: inline ? theme.spacing(0, 0.5) : theme.spacing(0.5, 1),
      whiteSpace: "nowrap",
    },
    startIcon: { marginLeft: 0 },
  };
});

const ActionsButtonInner = memo(
  forwardRef(
    ({ disabled = false, level, hover = {}, shift = {}, ...rest }, ref) => {
      const isHovering = useHover(ref);
      const [
        isShiftDown,
        { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
      ] = useBoolean(false);
      useKeyPress("shift", setIsShiftDownTrue, { events: ["keydown"] });
      useKeyPress("shift", setIsShiftDownFalse, { events: ["keyup"] });
      const { disabled: hoverDisabled = false } = hover || {};
      const { disabled: shiftDisabled = false } = shift || {};
      const {
        icon,
        iconColor,
        icon: Icon,
        inline,
        onClick = () => {},
        loading = false,
        name,
        sx,
        isRed,
      } = {
        ...rest,
        ...(!hoverDisabled && isHovering ? hover : {}),
        ...(!shiftDisabled && isShiftDown ? shift : {}),
      };
      const { classes: buttonClasses } = useButtonStyles({
        inline,
        isRed,
        level,
      });
      return (
        <Button
          classes={buttonClasses}
          color="inherit"
          data-testid={rest.dataTestid || name}
          disabled={disabled || loading}
          onClick={onClick}
          ref={ref}
          startIcon={
            loading ? (
              <CircularProgress color="inherit" size={15} thickness={3} />
            ) : icon ? (
              isElement(icon) || typeof icon === "string" ? (
                icon
              ) : isValidElementType(icon) ? (
                <Icon />
              ) : (
                <FontAwesomeIcon
                  {...(iconColor ? { color: iconColor } : {})}
                  icon={icon}
                />
              )
            ) : null
          }
          sx={sx}
          {...rest}
        >
          {name}
        </Button>
      );
    }
  )
);

ActionsButtonInner.displayName = "ActionsButtonInner";

const ActionsButton = memo(
  ({ children = null, tooltipPlacement = "top", ...rest }) => {
    const ref = useRef();
    const isHovering = useHover(ref);
    const [
      isShiftDown,
      { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
    ] = useBoolean(false);
    useKeyPress("shift", setIsShiftDownTrue, { events: ["keydown"] });
    useKeyPress("shift", setIsShiftDownFalse, { events: ["keyup"] });
    const { hover = {}, shift = {} } = rest || {};
    const { disabled: hoverDisabled = false } = hover || {};
    const { disabled: shiftDisabled = false } = shift || {};
    const { name, tooltipTitle } = {
      ...rest,
      ...(!hoverDisabled && isHovering ? hover : {}),
      ...(!shiftDisabled && isShiftDown ? shift : {}),
    };
    return (
      <Fragment>
        <Tooltip
          disableFocusListener={true}
          disableTouchListener={true}
          placement={tooltipPlacement}
          title={figureOutTooltipTitle(name, tooltipTitle)}
        >
          <div style={{ display: "flex" }}>
            <ActionsButtonInner ref={ref} {...rest} />
          </div>
        </Tooltip>
        {children}
      </Fragment>
    );
  }
);

ActionsButton.displayName = "ActionsButton";

const ActionsCustomButton = memo(
  ({ component: Component, children = null, ...rest }) => {
    const ref = useRef();
    const isHovering = useHover(ref);
    const [
      isShiftDown,
      { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
    ] = useBoolean(false);
    useKeyPress("shift", setIsShiftDownTrue, { events: ["keydown"] });
    useKeyPress("shift", setIsShiftDownFalse, { events: ["keyup"] });
    const { hover = {}, shift = {} } = rest || {};
    const { disabled: hoverDisabled = false } = hover || {};
    const { disabled: shiftDisabled = false } = shift || {};
    const {
      name,
      tooltipTitle,
      tooltipPlacement = "top",
    } = {
      ...rest,
      ...(!hoverDisabled && isHovering ? hover : {}),
      ...(!shiftDisabled && isShiftDown ? shift : {}),
    };
    return (
      <Fragment>
        <Tooltip
          disableFocusListener={true}
          disableTouchListener={true}
          placement={tooltipPlacement}
          title={figureOutTooltipTitle(name, tooltipTitle)}
        >
          <div>
            {isValidElementType(Component) ? (
              <Component ref={ref} {...rest} />
            ) : isElement(Component) ? (
              cloneElement(Component, { ...rest, ref })
            ) : null}
          </div>
        </Tooltip>
        {children}
      </Fragment>
    );
  }
);

ActionsCustomButton.displayName = "ActionsCustomButton";

interface ActionsRawButtonProps {
  childComponent: React.ReactComponentElement;
  [key: string]: any;
}

export const ActionsRawButton = memo<ActionsRawButtonProps>(
  ({ childComponent: Component, ...rest }) => <Component {...rest} />
);

ActionsRawButton.displayName = "ActionsRawButton";

const useIconButtonStyles = makeStyles()(
  (theme, { square, isRed, inline }) => ({
    root: {
      borderRadius: square ? theme.shape.borderRadius : "100%",
      color: isRed ? theme.palette.error.main : "inherit",
      minWidth: 0,
      padding: theme.spacing(inline ? 0.5 : 1),
    },
  })
);

const ActionsIconButtonInner = memo(
  forwardRef(({ disabled = false, hover = {}, shift = {}, ...rest }, ref) => {
    const isHovering = useHover(ref);
    const [
      isShiftDown,
      { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
    ] = useBoolean(false);
    useKeyPress("shift", setIsShiftDownTrue, { events: ["keydown"] });
    useKeyPress("shift", setIsShiftDownFalse, { events: ["keyup"] });
    const { disabled: hoverDisabled = false } = hover || {};
    const { disabled: shiftDisabled = false } = shift || {};
    const {
      icon,
      icon: Icon,
      inline,
      onClick = () => {},
      loading = false,
      square = true,
      circle,
      isRed,
      iconColor,
      sx,
    } = {
      ...rest,
      ...(!hoverDisabled && isHovering ? hover : {}),
      ...(!shiftDisabled && isShiftDown ? shift : {}),
    };
    const { classes: iconButtonClasses } = useIconButtonStyles({
      circle,
      inline,
      isRed,
      square,
    });
    return (
      <IconButton
        TouchRippleProps={{ center: inline }}
        classes={iconButtonClasses}
        color="inherit"
        disabled={disabled || loading}
        onClick={onClick}
        ref={ref}
        sx={sx}
      >
        {loading ? (
          <CircularProgress color="inherit" size="1rem" thickness={3} />
        ) : icon ? (
          isElement(icon) || typeof icon === "string" ? (
            icon
          ) : isValidElementType(icon) ? (
            <Icon />
          ) : (
            <FontAwesomeIcon
              {...(iconColor ? { color: iconColor } : {})}
              fixedWidth={true}
              icon={icon}
            />
          )
        ) : null}
      </IconButton>
    );
  })
);

ActionsIconButtonInner.displayName = "ActionsIconButtonInner";

const ActionsIconButton = memo(
  ({
    children = null,
    startAdornment = null,
    tooltipPlacement = "top",
    ...rest
  }) => {
    const ref = useRef();
    const isHovering = useHover(ref);
    const [
      isShiftDown,
      { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
    ] = useBoolean(false);
    useKeyPress("shift", setIsShiftDownTrue, { events: ["keydown"] });
    useKeyPress("shift", setIsShiftDownFalse, { events: ["keyup"] });
    const { hover = {}, shift = {} } = rest || {};
    const { disabled: hoverDisabled = false } = hover || {};
    const { disabled: shiftDisabled = false } = shift || {};
    const { tooltipTitle = "" } = {
      ...rest,
      ...(!hoverDisabled && isHovering ? hover : {}),
      ...(!shiftDisabled && isShiftDown ? shift : {}),
    };
    return (
      <Fragment>
        {startAdornment}
        <Tooltip
          disableFocusListener={true}
          disableTouchListener={true}
          placement={tooltipPlacement}
          title={tooltipTitle}
        >
          <div
            data-testid={testIds.components.actions.actionButton}
            style={{ display: "flex" }}
          >
            <ActionsIconButtonInner ref={ref} {...rest} />
          </div>
        </Tooltip>
        {children}
      </Fragment>
    );
  }
);

ActionsIconButton.displayName = "ActionsIconButton";

const useMenuItemStyles = makeStyles()((theme, { isRed }) => ({
  root: {
    color: isRed ? theme.palette.error.main : "inherit",
    minHeight: "auto",
  },
}));

const useActionsMenuItemStyles = makeStyles()((theme) => ({
  iconNameWrapper: {
    alignItems: "flex-start",
    display: "flex",
  },
  iconWrapper: {
    alignItems: "center",
    display: "flex",
    height: "1.25rem",
    lineHeight: "1.25rem",
    marginRight: theme.spacing(1),
  },
  nameWrapper: {
    lineHeight: "1.25rem",
    width: "100%",
  },
}));

const ActionsMenuItemInner = memo(
  forwardRef(({ disabled = false, hover = {}, shift = {}, ...rest }, ref) => {
    const { closePopover } = useActions();
    const isHovering = useHover(ref);
    const [
      isShiftDown,
      { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
    ] = useBoolean(false);
    useKeyPress("shift", setIsShiftDownTrue, { events: ["keydown"] });
    useKeyPress("shift", setIsShiftDownFalse, { events: ["keyup"] });
    const { disabled: hoverDisabled = false } = hover || {};
    const { disabled: shiftDisabled = false } = shift || {};
    const {
      icon,
      icon: Icon,
      iconColor,
      name,
      name: Name,
      onClick = () => {},
      loading = false,
      isRed,
      sx,
    } = {
      ...rest,
      ...(!hoverDisabled && isHovering ? hover : {}),
      ...(!shiftDisabled && isShiftDown ? shift : {}),
    };
    const { classes: menuItemClasses } = useMenuItemStyles({
      isRed,
    });
    const { classes: actionsMenuItemClasses } = useActionsMenuItemStyles();
    const { iconNameWrapper, iconWrapper, nameWrapper } =
      actionsMenuItemClasses;
    return (
      <div
        data-testid={`${testIds.components.actions.actionButtonHelper}${name}`}
        onClick={closePopover}
      >
        <MenuItem
          classes={menuItemClasses}
          disabled={disabled || loading}
          onClick={onClick}
          ref={ref}
          style={{ display: "block" }}
          sx={sx}
        >
          <div className={iconNameWrapper}>
            {loading ? (
              <div className={iconWrapper}>
                <CircularProgress color="inherit" size="1rem" thickness={3} />
              </div>
            ) : icon ? (
              <div className={iconWrapper}>
                {isElement(icon) || typeof icon === "string" ? (
                  icon
                ) : isValidElementType(icon) ? (
                  <Icon />
                ) : (
                  <FontAwesomeIcon
                    {...(iconColor ? { color: iconColor } : {})}
                    fixedWidth={true}
                    icon={icon}
                  />
                )}
              </div>
            ) : null}
            {name ? (
              <div className={nameWrapper}>
                {isElement(name) || typeof name === "string" ? (
                  name
                ) : isValidElementType(name) ? (
                  <Name />
                ) : null}
              </div>
            ) : null}
          </div>
        </MenuItem>
      </div>
    );
  })
);

ActionsMenuItemInner.displayName = "ActionsMenuItemInner";

const ActionsMenuItem = memo(
  ({ children = null, tooltipPlacement = "top", ...rest }) => {
    const ref = useRef();
    const isHovering = useHover(ref);
    const [
      isShiftDown,
      { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
    ] = useBoolean(false);
    useKeyPress("shift", setIsShiftDownTrue, { events: ["keydown"] });
    useKeyPress("shift", setIsShiftDownFalse, { events: ["keyup"] });
    const { hover = {}, shift = {} } = rest || {};
    const { disabled: hoverDisabled = false } = hover || {};
    const { disabled: shiftDisabled = false } = shift || {};
    const { name, tooltipTitle } = {
      ...rest,
      ...(!hoverDisabled && isHovering ? hover : {}),
      ...(!shiftDisabled && isShiftDown ? shift : {}),
    };
    return (
      <Fragment>
        <Tooltip
          disableFocusListener={true}
          disableTouchListener={true}
          placement={tooltipPlacement}
          title={figureOutTooltipTitle(name, tooltipTitle)}
        >
          <div>
            <ActionsMenuItemInner ref={ref} {...rest} />
          </div>
        </Tooltip>
        {children}
      </Fragment>
    );
  }
);

ActionsMenuItem.displayName = "ActionsMenuItem";

const useActionsStyles = makeStyles()((theme) => ({
  actionsWrapper: {
    display: "flex",
  },
}));

// TODO: Configure MuiPaper in the <StyleWrapper />
const usePaperStyles = makeStyles()((theme) => ({
  root: {
    boxShadow: "none !important",
  },
}));

const USE_REGULAR_ELLIPSIS_ICON = true;

export interface ActionsItemsDefinition {
  buttons?: any[];
  menuLists?: any[];
}

interface ActionsProps {
  inline?: boolean;
  moreTooltipTitle?: string | Element;
  moreTooltipPlacement?: string;
  moreIcon?: IconDefinition;
  actions: any;
  children?: React.ReactNode;
  popoverStyle?: SxProps;
  menuListStyle?: SxProps;
  withActionsSeparator?: boolean;
}

// TODO: perhaps add focused state too 🥵
// TODO: split this module by components
// TODO: add memoization, figure out refs
// TODO: add text blocks for simple info like in Notion
// TODO: dedup buttons and menu items, i.e. if button is present then hide the menu item
const Actions = memo(
  forwardRef<HTMLDivElement, ActionsProps>(
    (
      {
        inline = false,
        moreTooltipTitle = "Actions",
        moreTooltipPlacement = "top",
        moreIcon = USE_REGULAR_ELLIPSIS_ICON ? faEllipsisVRegular : faEllipsisV,
        actions = {},
        // Boolean for hiding/showing Divider that plays a role of a border
        withActionsSeparator = true,
        children,
        popoverStyle = {},
        menuListStyle = {},
      },
      ref
    ) => {
      const { classes: paperClasses } = usePaperStyles();
      const { classes: actionsClasses } = useActionsStyles();
      const { actionsWrapper } = actionsClasses;
      const { anchorEl, isOpen, open, close } = usePopupState({
        variant: "popover",
      });
      const openPopover = useCallback(
        (event) => {
          event.stopPropagation();
          open(event.currentTarget);
        },
        [open]
      );
      const closePopover = useCallback(
        (event) => {
          event.stopPropagation();
          close(event.currentTarget);
        },
        [close]
      );
      const stopPropagation = useCallback((event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
      }, []);
      const { buttons = [], menuLists = [] } = actions;
      return (
        <ActionsContext.Provider value={{ closePopover, openPopover }}>
          <div
            className={actionsWrapper}
            onClick={stopPropagation}
            onMouseDown={stopPropagation}
            onMouseUp={stopPropagation}
            ref={ref}
          >
            {buttons
              .filter(({ hidden = false }) => !hidden)
              .map(({ component = IconButton, ...action }, index) => {
                if (component === Button)
                  return <ActionsButton key={index} {...action} />;
                if (component === IconButton) {
                  return <ActionsIconButton key={index} {...action} />;
                }
                if (component === ActionsRawButton) {
                  return <ActionsRawButton key={index} {...action} />;
                }
                if (
                  component &&
                  (isElement(component) || isValidElementType(component))
                ) {
                  return (
                    <ActionsCustomButton
                      component={component}
                      key={index}
                      {...action}
                    />
                  );
                }
                return <ActionsIconButton key={index} {...action} />;
              })}
            {menuLists.flat(Infinity).some(({ hidden = false }) => !hidden) ? (
              <Fragment>
                <ActionsIconButton
                  {...{
                    disabled: !menuLists
                      .flat(Infinity)
                      .filter(({ hidden = false }) => !hidden)
                      .some(({ disabled = false }) => !disabled),
                    icon: moreIcon,
                    inline,
                    onClick: openPopover,
                    square: true,
                    tooltipPlacement: moreTooltipPlacement,
                    tooltipTitle: moreTooltipTitle,
                  }}
                />
                <Popover
                  PaperProps={{ classes: paperClasses }}
                  anchorEl={anchorEl}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  marginThreshold={8}
                  onClose={closePopover}
                  open={isOpen}
                  sx={{ ...popoverStyle }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  {menuLists
                    .map((menuList, index) => {
                      const nonEmptyActions = menuList.filter(
                        ({ hidden = false }) => !hidden
                      );
                      if (!nonEmptyActions.length) {
                        return undefined;
                      }
                      return (
                        <MenuList key={index} sx={menuListStyle}>
                          {nonEmptyActions.map((action, index) => {
                            return <ActionsMenuItem key={index} {...action} />;
                          })}
                        </MenuList>
                      );
                    })
                    .filter(Boolean)
                    .reduce((menuLists, menuList, index) => [
                      menuLists,
                      withActionsSeparator ? (
                        <Divider key={`divider-${index}`} />
                      ) : (
                        []
                      ),
                      menuList,
                    ])}
                </Popover>
              </Fragment>
            ) : null}
            {children}
          </div>
        </ActionsContext.Provider>
      );
    }
  )
);

Actions.displayName = "Actions";

export default Actions;
