import {
  useEnclaveWorkersQuery,
  useOrganizationEnclaveWorkersQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationEnclaveWorkersMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  OrganizationState,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { Box, FormControl, FormGroup, FormLabel } from "@mui/material";
import { Fragment, memo, useCallback, useMemo } from "react";
import { Checkbox, Chip } from "components";
import { WORKER_TYPE_LABEL } from "features/computeNode/components/ComputeNodeType/useComputeNodeType";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { WORKER_TYPE_COLOR } from "models";

interface OrganizationEnclaveWorkersEditorProps {
  organizationId: string;
}

const OrganizationEnclaveWorkersEditor: React.FC<
  OrganizationEnclaveWorkersEditorProps
> = ({ organizationId }) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  // List of all existing enclave workers
  const { data: enclaveWorkersData } = useEnclaveWorkersQuery({
    skip: !isSuperAdmin,
  });
  const enclaveWorkers = enclaveWorkersData?.workerTypes?.nodes || [];

  // List of enclave workers available to this organization
  const { data: organizationEnclaveWorkersData } =
    useOrganizationEnclaveWorkersQuery({
      variables: { organizationId },
    });
  const availableEnclaveWorkers = useMemo(
    () =>
      organizationEnclaveWorkersData?.organization?.workerTypes?.nodes || [],
    [organizationEnclaveWorkersData]
  );

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationEnclaveWorkersMutation] =
    useUpdateOrganizationEnclaveWorkersMutation();

  const updateOrganizationEnclaveWorkers = useCallback(
    async ({
      target: { value: workerId = "" },
    }: React.ChangeEvent<HTMLInputElement>) => {
      let newWorkersArray = [];
      if (availableEnclaveWorkers.some(({ id }) => id === workerId)) {
        // If selected worker is in availableEnclaveWorkers array - then it should be removed from a list
        newWorkersArray = availableEnclaveWorkers.reduce<string[]>(
          (workersArray, { id }) =>
            id === workerId ? workersArray : [id, ...workersArray],
          []
        );
      } else {
        // Adding new worker to the array otherwise
        const availableEnclaveWorkersIds = availableEnclaveWorkers.map(
          ({ id }) => id
        );
        newWorkersArray = [...availableEnclaveWorkersIds, workerId];
      }
      try {
        await updateOrganizationEnclaveWorkersMutation({
          variables: {
            input: {
              id: organizationId,
              workerTypes: newWorkersArray,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          "Available computations settings could not be updated.",
          {
            context: error?.message,
            persist: true,
            variant: "error",
          }
        );
      }
    },
    [
      enqueueSnackbar,
      availableEnclaveWorkers,
      organizationId,
      updateOrganizationEnclaveWorkersMutation,
    ]
  );

  const isEditorDisabled = [
    OrganizationState.Passive,
    OrganizationState.Suspended,
  ].includes(organizationLicense);

  return (
    <FormControl>
      <FormLabel>Available computation types</FormLabel>
      {isSuperAdmin ? (
        <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
          {enclaveWorkers.map(({ id, shortName }) => (
            <Checkbox
              checked={availableEnclaveWorkers.some(
                (worker) => worker.id === id
              )}
              disabled={isEditorDisabled}
              key={id}
              label={
                <span>
                  {WORKER_TYPE_LABEL[shortName]}{" "}
                  {shortName === WorkerTypeShortName.Python && (
                    <Checkbox
                      checked={availableEnclaveWorkers.some(
                        (worker) => worker.id === id
                      )}
                      disabled={true}
                      label="Matching"
                      sx={{ m: 0 }}
                    />
                  )}
                </span>
              }
              onChange={updateOrganizationEnclaveWorkers}
              sx={{ ml: 0 }}
              value={id}
            />
          ))}
        </FormGroup>
      ) : availableEnclaveWorkers.length ? (
        <Box sx={{ mt: 0.5 }}>
          {availableEnclaveWorkers.map(({ shortName }) =>
            shortName === WorkerTypeShortName.Python ? (
              <Fragment>
                <Chip
                  label={shortName}
                  sx={{
                    backgroundColor:
                      WORKER_TYPE_COLOR[
                        shortName as keyof typeof WORKER_TYPE_COLOR
                      ],
                    mr: 1,
                  }}
                />
                <Chip
                  label="MATCHING"
                  sx={{
                    backgroundColor:
                      WORKER_TYPE_COLOR[
                        WorkerTypeShortName.Match as keyof typeof WORKER_TYPE_COLOR
                      ],
                    mr: 1,
                  }}
                />
              </Fragment>
            ) : (
              <Chip
                label={shortName}
                sx={{
                  backgroundColor:
                    WORKER_TYPE_COLOR[
                      shortName as keyof typeof WORKER_TYPE_COLOR
                    ],
                  mr: 1,
                }}
              />
            )
          )}
        </Box>
      ) : (
        "—"
      )}
    </FormControl>
  );
};

OrganizationEnclaveWorkersEditor.displayName =
  "OrganizationEnclaveWorkersEditor";

export default memo(OrganizationEnclaveWorkersEditor);
