import { DOMAIN_REGEXP } from "constants/index";
import { InfoTooltip } from "@decentriq/components";
import {
  Box,
  Chip as MuiChip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Switch,
} from "@mui/material";
import { useUpdateEffect } from "ahooks";
import { isEmpty } from "lodash";
import { memo, useCallback, useState } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  type PathValue,
  useFormContext,
  useFormState,
} from "react-hook-form";

interface ConfigurationFormDomainFieldProps<F extends FieldValues> {
  textFieldName: Path<F>;
  checkboxFieldName: Path<F>;
  textFieldTestId?: string;
  checkboxTestId?: string;
}

type ConfigurationFormDomainFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormDomainFieldProps<F>
) => React.ReactNode;

const ConfigurationFormDomainField = memo(
  <F extends FieldValues>({
    textFieldName,
    checkboxFieldName,
    textFieldTestId,
    checkboxTestId,
  }: ConfigurationFormDomainFieldProps<F>) => {
    const [domain, setDomain] = useState<string>("");
    const [isTouched, setIsTouched] = useState<boolean>(false);

    const {
      control: formControl,
      clearErrors,
      watch,
      setValue,
      setError,
    } = useFormContext<F>();
    const { errors } = useFormState({ control: formControl });

    const advertiserDomains = watch(textFieldName);
    const matchAnyDomain = watch(checkboxFieldName);

    useUpdateEffect(() => {
      if (matchAnyDomain) {
        setDomain("");
        setIsTouched(false);
        clearErrors(textFieldName);
        setValue(textFieldName, [] as PathValue<F, Path<F>>);
      }
    }, [matchAnyDomain]);

    useUpdateEffect(() => {
      // Handle check for already existing domains
      if (advertiserDomains?.includes(domain)) {
        setError(textFieldName, {
          message: "Domain is already in use",
          type: "custom",
        });
        // Handle check for domain validity
      } else if (domain && !domain.match(DOMAIN_REGEXP)) {
        setError(textFieldName, {
          message: "Domain is not valid",
          type: "custom",
        });
      } else {
        clearErrors(textFieldName);
      }
    }, [domain, advertiserDomains, clearErrors, setError]);

    const handleDomainChange = useCallback(
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setDomain(target.value);
        if (!isTouched) setIsTouched(true);
      },
      [isTouched]
    );

    const addAdvertiserDomain: React.FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      (event) => {
        if (!event?.target?.value || !!errors?.advertiserDomains) return;
        setDomain("");
        setValue(textFieldName, [
          ...(advertiserDomains ?? []),
          event?.target?.value,
        ] as PathValue<F, Path<F>>);
      },
      [advertiserDomains, errors?.advertiserDomains, setValue, textFieldName]
    );

    const addAdvertiserDomainOnEnter: React.KeyboardEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      (event) => {
        if (event.keyCode !== 13 || !event.target.value) return;
        addAdvertiserDomain(event);
      },
      [addAdvertiserDomain]
    );

    const removeAdvertiserDomain = useCallback(
      (domain: string) => {
        const allowedAdvertiserDomains = (advertiserDomains ?? []).filter(
          (advertiserDomain: string) => advertiserDomain !== domain
        );
        setValue(
          textFieldName,
          allowedAdvertiserDomains as PathValue<F, Path<F>>
        );
      },
      [advertiserDomains, setValue, textFieldName]
    );
    return (
      <Controller
        control={formControl}
        name={textFieldName}
        render={({ formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[textFieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          return (
            <FormControl error={!isEmpty(fieldError)} fullWidth={true}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel>
                  Advertiser domain
                  <InfoTooltip tooltip="The domain (ETLD+1) of the advertiser this config applies to. Users at an organization registered to this domain will be able to create clean rooms using this config." />
                </FormLabel>
                <Controller
                  control={formControl}
                  name={checkboxFieldName}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Switch color="primary" size="small" {...field} />
                        }
                        data-testid={checkboxTestId}
                        label="Match any"
                        labelPlacement="end"
                        sx={{ m: 0 }}
                      />
                    </FormControl>
                  )}
                />
              </Box>
              <OutlinedInput
                data-testid={textFieldTestId}
                disabled={matchAnyDomain}
                onBlur={addAdvertiserDomain}
                onChange={handleDomainChange}
                onKeyDown={addAdvertiserDomainOnEnter}
                placeholder="Enter advertiser domain"
                value={domain}
              />
              {(advertiserDomains ?? []).length > 0 ? (
                <Stack
                  direction="row"
                  sx={{ flexWrap: "wrap", mb: 1, mt: 0.5 }}
                >
                  {((advertiserDomains || []) as string[]).map((domain) => (
                    <MuiChip
                      key={domain}
                      label={domain?.toUpperCase()}
                      onDelete={() => removeAdvertiserDomain(domain)}
                      sx={{
                        borderRadius: "2px",
                        mb: 0.5,
                        mr: 1,
                      }}
                    />
                  ))}
                </Stack>
              ) : null}
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
ConfigurationFormDomainField.displayName = "ConfigurationFormDomainField";

export default ConfigurationFormDomainField as ConfigurationFormDomainFieldComponent;
