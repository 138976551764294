import { InfoTooltip } from "@decentriq/components";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  useFormContext,
} from "react-hook-form";

interface ConfigurationFormNameFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  title: string;
  placeholder: string;
  tooltip?: string;
  testId?: string;
}

type ConfigurationFormNameFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormNameFieldProps<F>
) => React.ReactNode;

const ConfigurationFormNameField = memo(
  <F extends FieldValues>({
    fieldName,
    title,
    placeholder,
    tooltip,
    testId,
  }: ConfigurationFormNameFieldProps<F>) => {
    const { control: formControl } = useFormContext<F>();
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[fieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          return (
            <FormControl error={!isEmpty(errors[fieldName])} fullWidth={true}>
              <FormLabel>
                {title}
                {tooltip && <InfoTooltip tooltip={tooltip} />}
              </FormLabel>
              <OutlinedInput
                placeholder={placeholder}
                {...field}
                data-testid={testId}
              />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
ConfigurationFormNameField.displayName = "ConfigurationFormNameField";

export default ConfigurationFormNameField as ConfigurationFormNameFieldComponent;
