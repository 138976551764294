import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { type FocusEventHandler, type KeyboardEventHandler, memo } from "react";
import {
  type Control,
  Controller,
  type FieldError,
  type Merge,
} from "react-hook-form";

interface ParticipantsEditorProps {
  readonly: boolean;
  formControl: Control;
  emails: string[];
  fieldName?: string;
  onEmailRemove?: (email: string) => void;
  placeholder: string;
  testId?: string;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const ParticipantsEditor = memo<ParticipantsEditorProps>(
  ({
    readonly,
    formControl,
    emails,
    fieldName,
    onEmailRemove,
    placeholder,
    testId,
    onBlur,
    onKeyDown,
  }) => {
    const chips = emails.map((email) => (
      <Chip
        key={email}
        label={email}
        onDelete={onEmailRemove ? () => onEmailRemove(email) : undefined}
        sx={({ shape }) => ({
          borderRadius: `${shape.borderRadius}px !important`,
          fontWeight: "normal",
          mb: 0.25,
          mr: 1,
          mt: 0.25,
        })}
        variant="outlined"
      />
    ));
    if (readonly || !fieldName) {
      return <Box>{chips}</Box>;
    }
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, fieldState }) => {
          const fieldError = fieldState.error as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          const hasError = !isEmpty(fieldError);
          return (
            <FormControl
              error={hasError}
              fullWidth={true}
              sx={{ height: "100%", position: "relative" }}
            >
              <OutlinedInput
                autoComplete="off"
                inputProps={{
                  sx: {
                    "&.MuiInput-input": { p: 0, width: "250px" },
                  },
                }}
                placeholder={placeholder}
                startAdornment={
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "calc(100% - 250px)",
                    }}
                  >
                    {chips}
                  </Box>
                }
                sx={{
                  "&:after": {
                    border: "none",
                  },
                  "&:before": {
                    border: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):after": {
                    border: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    border: "none",
                  },
                  height: "100%",
                }}
                {...field}
                data-testid={testId}
                onBlur={(event) => {
                  field.onBlur();
                  onBlur(event);
                }}
                onKeyDown={onKeyDown}
              />
              <FormHelperText
                sx={(theme) => ({
                  bottom: 0,
                  m: 0,
                  position: "absolute",
                  right: 0,
                })}
              >
                {fieldError?.message || ""}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);

ParticipantsEditor.displayName = "ParticipantsEditor";

export default ParticipantsEditor;
