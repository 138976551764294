import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useMatch } from "react-router-dom";
import {
  DataLabCreateButton,
  DataLabsList,
  DataLabsWrapper,
} from "features/dataLabs";

const DataLabs: React.FC = () => {
  return (
    <DataLabsWrapper>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: (theme) => theme.spacing(2, 2, 1),
        }}
      >
        <Box sx={{ marginLeft: "auto", width: "fit-content" }}>
          <DataLabCreateButton />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            overflow: "hidden",
          }}
        >
          <DataLabsList />
        </Box>
      </Box>
    </DataLabsWrapper>
  );
};

export default memo(DataLabs);
