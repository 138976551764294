import {
  useApolloClient,
  useFragment_experimental as useFragment,
} from "@apollo/client";
import { useDraftDataRoomTestPublicationsQuery } from "@decentriq/graphql/dist/hooks";
import {
  DraftDataRoomPasswordFragment,
  type DraftRawLeafNode,
  type DraftTableLeafNode,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { faFileContract as faFileContractRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DraftDataNodeTypeNames } from "models";

const ALLOW_TEST_DATA_TRANSFERING = false;

interface DataRoomPublishDialogProps {
  dataRoomId: string;
  requirePassword: boolean;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (keepTestData: boolean) => void;
}

const DataRoomPublishDialog: React.FC<DataRoomPublishDialogProps> = ({
  dataRoomId,
  requirePassword,
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  const client = useApolloClient();
  const { cache } = client;
  const { data: testPublicationsData } = useDraftDataRoomTestPublicationsQuery({
    skip: !ALLOW_TEST_DATA_TRANSFERING,
    variables: {
      dataRoomId,
    },
  });
  const hasTestPublications =
    testPublicationsData?.draftDataRoom?.draftNodes?.nodes?.some(
      ({ id, __typename, ...rest }) =>
        __typename === DraftDataNodeTypeNames.DraftRawLeafNode ||
        (__typename === DraftDataNodeTypeNames.DraftTableLeafNode &&
          (rest as DraftTableLeafNode | DraftRawLeafNode).testModePublication)
    );
  const { data: dataRoomPasswordData } = useFragment({
    fragment: DraftDataRoomPasswordFragment,
    from: { __typename: "DraftDataRoom", id: dataRoomId },
  });
  const [keepTestData, setKeepTestData] = useState(true);
  const password = dataRoomPasswordData?.password || undefined;
  const setPassword = (password: string) => {
    cache.writeFragment({
      data: { password },
      fragment: DraftDataRoomPasswordFragment,
      id: cache.identify({ __typename: "DraftDataRoom", id: dataRoomId }),
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  useEffect(() => {
    if ((password?.length || 0) < 8) {
      return setValidationErrors([
        "Password must contain at least 8 characters.",
      ]);
    }
    setValidationErrors(undefined);
  }, [password]);
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open}>
      <DialogContent>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon fixedWidth={true} icon={faInfoCircle} size="3x" />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom={true}
          style={{ lineHeight: 1.25 }}
          variant="h6"
        >
          <strong>
            This publishes the data clean room and sends invitation emails to
            all participants.
          </strong>
        </Typography>
        {hasTestPublications && (
          <div>
            <Typography align="center" color="textPrimary">
              Test data was used for drafting the data clean room. Would you
              like to keep it?
              <br />
              Note: test data will only be accesible to you
            </Typography>
            <FormControl style={{ width: "100%" }}>
              <RadioGroup
                onChange={(e) =>
                  setKeepTestData(e.target.value as unknown as boolean)
                }
                row={true}
                sx={{ display: "flex", justifyContent: "space-around" }}
                value={keepTestData}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Yes, keep test data accesible to me"
                  value={true}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="No, delete it"
                  value={false}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        {requirePassword ? (
          <>
            <Divider style={{ margin: "1rem 0" }} />
            <div>
              <Typography align="center" color="textPrimary">
                Please set a password to continue.
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (isValid) {
                    onConfirm(keepTestData);
                  }
                }}
              >
                <FormControl
                  fullWidth={true}
                  sx={{ mt: 1, width: "100%" }}
                  variant="filled"
                >
                  <InputLabel>Password</InputLabel>
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword}>
                          {showPassword ? (
                            <FontAwesomeIcon
                              fixedWidth={true}
                              icon={faEyeSlash}
                            />
                          ) : (
                            <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(event) => setPassword(event.target.value)}
                    type={showPassword ? "text" : "password"}
                    value={password || ""}
                  />
                </FormControl>
              </form>
              {!isValid ? (
                <Alert severity="error">
                  <strong>Invalid password:</strong>
                  <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
                    {validationErrors?.map((validationError, index) => (
                      <li key={index}>{validationError}</li>
                    ))}
                  </ul>
                </Alert>
              ) : null}
              <Alert severity="warning">
                <strong>Notes:</strong>
                <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
                  <li>
                    You need to share the password with the participants before
                    they can interact with this data clean room.
                  </li>
                  <li>
                    If you lose your password, you will no longer be able to
                    access this data clean room.
                  </li>
                </ul>
              </Alert>
            </div>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={onCancel}>
          Go back to editing
        </Button>
        <LoadingButton
          color="primary"
          data-testid={testIds.dataroom.dataroomPublishDialog.publishButton}
          loading={loading}
          loadingPosition="start"
          onClick={() => onConfirm(keepTestData)}
          startIcon={<FontAwesomeIcon icon={faFileContractRegular} />}
          variant="contained"
        >
          Publish data clean room
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DataRoomPublishDialog;
